import React from 'react';
import { Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
} from 'chart.js';
// import Chart from 'chart.js/auto';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

function AreaChart({ chartData }) {
	return (
		<Line
			data={chartData}
			options={{
				responsive: true,
				plugins: {
					legend: false,
				},
				scales: {
					x: {
						grid: {
							display: false,
						},
					},
					y: {
						grid: {
							display: false,
						},
					},
				},
			}}
			width="120%"
			height="29px"
		/>
	);
}

export default AreaChart;
