import React, { useState, useEffect, useRef } from 'react';
import ReactTable from 'react-table';
import Button from 'components/button/Button';
import Pagination from 'components/common/Pagination';
import 'react-table/react-table.css';
import { withRouter } from 'react-router';
import Loader from '../common/Loader';
import moment from 'moment';
import Toaster from '../common/Toaster';
import { STATUS_CODES, TABLE } from '../../helper/constant';
import Api from 'helper/Api';
import CommonModel from '../common/CommonModel';
import View from './ViewModal';
// import Placeholder from '../../assets/images/Logo.png';
import RoyTooltip from 'components/common/RoyTooltip';
// import Delete from './DeleteModal';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AuthActions from '../../redux/auth/actions';
import ConfirmModal from './ConfirmationModal';
const { admin } = AuthActions;

const UserTable = (props) => {
	const {
		sidebarTheme: { activeColor },
	} = props;
	const { role } = props;
	const columns = [
		// {
		//   Header: "Image",
		//   accessor: "profile_photo",
		//   className: "text-center",
		//   sortable: false,
		//   filterable: false,
		//   headerClassName: "react-table-header-class",
		//   Cell: (props) => (
		//     <div>
		//       <img
		//         src={
		//           props.original.profile_photo
		//             ? props.original.profile_photo
		//             : Placeholder
		//         }
		//         alt="error"
		//         style={{ height: "30px", width: "30px", borderRadius: "50%" }}
		//       />
		//     </div>
		//   ),
		// },
		{
			Header: 'First Name',
			accessor: 'firstName',
			className: 'text-center',
			filterable: false,
			headerClassName: 'react-table-header-class',
		},
		{
			Header: 'Last Name',
			accessor: 'lastName',
			className: 'text-center',
			filterable: false,
			headerClassName: 'react-table-header-class',
		},
		{
			Header: 'Email',
			accessor: 'email',
			className: 'text-center',
			filterable: false,
			headerClassName: 'react-table-header-class',
		},
		{
			Header: 'Date',
			accessor: 'createdAt',
			className: 'text-center',
			filterable: false,
			headerClassName: 'react-table-header-class',
			Cell: (props) => <div>{moment(props.original.createdAt).format('MMM-DD-YYYY')}</div>,
		},
		{
			Header: 'Status',
			accessor: 'status',
			className: 'text-center',
			headerClassName: 'react-table-header-class',
			sortable: false,
			filterable: false,
			Cell: (props) => (
				<div className="react-action-class">
					{role === 1 ? (
						props.original.isActive ? (
							<div id={`active-${props.original.id}`}>
								<RoyTooltip id={`act-${props.original.id}`} title={'Click to Deactivate'} placement="left">
									<Button
										className="c-btn c-success mr-10"
										style={{ minWidth: '50px' }}
										onClick={() => activeInactiveStatusHandler(props.original)}
										id={`act-${props.original.id}`}
									>
										<div className="fs-14 ">
											<i class="fa fa-check" aria-hidden="true"></i>
										</div>
									</Button>
								</RoyTooltip>
							</div>
						) : (
							<div id={`deactive-${props.original.id}`}>
								<RoyTooltip id={`dact-${props.original.id}`} title={'Click to Activate'} placement="left">
									<Button
										style={{ minWidth: '50px' }}
										className="c-btn c-danger mr-10"
										onClick={() => activeInactiveStatusHandler(props.original)}
										id={`dact-${props.original.id}`}
									>
										<div>
											<div className="fs-14">
												<i class="fa fa-times" aria-hidden="true"></i>
											</div>
										</div>
									</Button>
								</RoyTooltip>
							</div>
						)
					) : (
						'-'
					)}
				</div>
			), // Custom cell components!
		},
		{
			Header: 'Action',
			accessor: 'action',
			className: 'text-center',
			headerClassName: 'react-table-header-class',
			sortable: false,
			filterable: false,
			Cell: (props) => (
				<div className="react-action-class">
					<RoyTooltip id={`view-${props.original.id}`} title={'View'} placement="left">
						<Button
							className="c-btn c-primary mr-10"
							style={{ minWidth: '50px' }}
							id={`view-${props.original.id}`}
							onClick={() => {
								setView(true);
								setModal(true);
								setAdminData(props.original);
							}}
						>
							<div className="fs-14 medium-text">
								<i className="fas fa-info" />
							</div>
						</Button>
					</RoyTooltip>
					{role === 1 ? (
						<>
							<RoyTooltip id={`edit-${props.original.id}`} title={'Edit'} placement="left">
								<Button
									style={{ minWidth: '50px' }}
									className="c-btn c-success mr-10"
									onClick={() => formAction('edit', props.original)}
									id={`edit-${props.original.id}`}
								>
									<div className="fs-14 medium-text">
										<i className="fas fa-edit" />
									</div>
								</Button>
							</RoyTooltip>
							<RoyTooltip id={`logout-${props.original.id}`} title={'Logout'} placement="left">
								<Button
									className="c-btn c-danger mr-10"
									style={{ minWidth: '50px' }}
									id={`logout-${props.original.id}`}
									disabled={props.original.isLogin === 0}  // Disable if isLogin is 0
									onClick={() => {
										if (props.original.isLogin !== 0) {
											confirmLogoutHandler(props.original.id);  // Open confirm modal only if not disabled
										}
									}}
								>
									<i className="fas fa-sign-out-alt" />
								</Button>
							</RoyTooltip>

						</>

					) : (
						''
					)}
				</div>
			), // Custom cell components!
		},
	];

	const [tblData, settblData] = useState();
	const [loader, setLoader] = useState(false);
	const [pages, setPages] = useState(0);
	const [activePage, setActivePage] = useState(0);
	const [searchText, setSearchText] = useState('');
	const [field, setField] = useState('createdAt');
	const [sortBy, setSortBy] = useState('DESC');
	const [modal, setModal] = useState(false);
	const [adminData, setAdminData] = useState({});
	const [view, setView] = useState(false);
	const [confirmLogoutModal, setConfirmLogoutModal] = useState(false); 
	const [selectedUserId, setSelectedUserId] = useState(null);  
	// const [deleteModal, setDeleteModal] = useState(false);
	// const [data, setData] = useState({});

	const toaster = useRef();

	useEffect(() => {
		getList(field, sortBy, searchText, activePage + 1);
		setPages(1);
	}, []);

	useEffect(() => {
		console.log('Modal state changed:', confirmLogoutModal);
		if (confirmLogoutModal) {
			console.log('The modal is now open.');
		}
	}, [confirmLogoutModal]);


	const formAction = (action, data = null) => {
		// Here you can edit data

		if (action === 'edit') {
			props.history.push(`/user/edit/${data.id}`);
		} else if (action === 'add') {
			props.history.push('/user/add');
		}
	};

	const confirmLogoutHandler = (userId) => {
		console.log(`User ${userId} logout button clicked`);
		setSelectedUserId(userId);
		setConfirmLogoutModal(true);  
		console.log('Modal should open:', confirmLogoutModal); 
	}

	const activeInactiveStatusHandler = (user) => {
		setLoader(true);
		Api('POST', `cms/user/change/status/${user.id}`)
			.then((response) => {
				if (response.status === STATUS_CODES.SUCCESS) {
					// if (toaster.current) toaster.current.success(response.data.message);
					getList(field, sortBy, searchText, activePage + 1);
					setLoader(false);
				}
			})
			.catch((error) => {
				if (toaster.current) toaster.current.error(error);
				setLoader(false);
			});
	};

	const onConfirmLogout = () => {
		if (selectedUserId) {
			handleLogout(selectedUserId);
		}
		setConfirmLogoutModal(false); 
	};

	const onCancelLogout = () => {
		setConfirmLogoutModal(false);
	};


	const handleLogout = (userId) => {
		Api('POST', `cms/user/logout/${userId}`, null, {
			headers: {
				Authorization: `Bearer ${props.token}`,
			}
		})
			.then((response) => {
				if (response.status === STATUS_CODES.SUCCESS) {
					toaster.current.success('User logged out successfully');
					getList();  // Refresh the list after logout
				}
			})
			.catch((error) => {
				toaster.current.error('Failed to log out');
			});
	};



	const onChangePageGetData = (state) => {
		if (state.page !== activePage) {
		}
	};

	function getList(field = 'createdAt', sortBy = 'DESC', search = '', page = '1') {
		const params = {
			sort_by: field,
			order: sortBy,
			search: search,
			page: page,
		};
		setLoader(true);

		Api('POST', 'cms/user/list', params)
			.then((res) => {
				if (res.status === STATUS_CODES.SUCCESS) {
					settblData(res.data.data.rows);
					setPages(Math.ceil(res.data.data.count / TABLE.LIMIT));
					setLoader(false);
				} else {
					setLoader(false);
					if (toaster.current) toaster.current.error(res.data.message);
				}
			})
			.catch((err) => {
				setLoader(false);
				if (toaster.current) toaster.current.error(err.response);
			});
	}

	return (
		<div>
			<div className="plr-15">
				<div className="roe-card-style mtb-15">
					<div className="roe-card-header module-header">
						<div className="flex-1 fs-16 demi-bold-text" />
						<div className="mr-10">
							<input
								value={searchText}
								onChange={(e) => {
									setSearchText(e.target.value);
									const search = e.target.value;
									setLoader(true);
									setActivePage(0);
									setTimeout(() => {
										getList(field, sortBy, search, 1);
									}, 500);
								}}
								type="text"
								placeholder="Search..."
								className="fs-14 medium-text plr-10 form-control react-form-input"
							/>
						</div>
						<div>
							{role === 1 ? (
								<Button
									className="c-btn ma-5"
									onClick={() => formAction('add')}
									style={{
										backgroundColor: activeColor,
										color: 'white',
									}}
								>
									<i className="fas fa-plus mr-10" />
									Add User
								</Button>
							) : (
								''
							)}
						</div>
					</div>
					<div className="roe-card-body">
						<ReactTable
							style={{
								border: 'none',
								boxShadow: 'none',
							}}
							data={tblData}
							columns={columns}
							defaultPageSize={10}
							filterable
							minRows={2}
							manual
							defaultFilterMethod={(filter, row) => {
								const id = filter.pivotId || filter.id;

								return row[id] !== undefined
									? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
									: true;
							}}
							className="-striped -highlight custom-react-table-theme-class"
							pages={pages}
							page={activePage}
							PaginationComponent={Pagination}
							loading={loader}
							LoadingComponent={Loader}
							onFetchData={onChangePageGetData}
							onPageChange={(pageIndex) => {
								setActivePage(pageIndex);
								getList(field, sortBy, searchText, pageIndex + 1);
							}}
							onSortedChange={(sortProperties) => {
								const sort = sortProperties[0].desc ? 'DESC' : 'ASC';
								setSortBy(sort);
								setActivePage(0);
								setField(sortProperties[0].id);
								getList(sortProperties[0].id, sort, searchText, 1);
							}}
						/>
					</div>

					{view && (
						<CommonModel
							modal={modal}
							size="lg"
							title="Admin information"
							style={{ maxWidth: '800px', width: '80%' }}
							children={
								<View
									adminData={adminData}
									toggle={() => {
										setModal(false);
									}}
								/>
							}
						/>
					)}
					{/* {deleteModal && (
            <CommonModel
              modal={deleteModal}
              size="lg"
              title="Admin information"
              style={{ maxWidth: "500px", width: "80%" }}
              children={
                <Delete
                  data={data}
                  toggle={() => {
                    setDeleteModal(false);
                    getList(field, sortBy, searchText, 1);
                  }}
                />
              }
            />
          )} */}
				</div>
			</div>
			<>
				{confirmLogoutModal && (
					<ConfirmModal
						show={confirmLogoutModal}
						onClose={onCancelLogout}
						onConfirm={onConfirmLogout}
					/>
				)}

			</>
			<Toaster ref={toaster} />
		</div>
	);
};
const mapStateToProps = (state) => {
	return {
		role: state.auth.role,
		id: state.auth.id,
		...state.themeChanger,
	};
};
export default compose(withRouter, connect(mapStateToProps, { admin }))(UserTable);
// export default withRouter(UserTable);
