import React, { useEffect, useState, useRef } from 'react';
import UserWrapper from './style';
import PageTitle from 'components/common/PageTitle';
import { connect } from 'react-redux';
import Toaster from '../../components/common/Toaster';
import UserForm from '../../components/user/UserForm';
import Api from 'helper/Api';
import { STATUS_CODES } from '../../helper/constant';

const UserEdit = (props) => {
	const hasParam = props.match.params.id;

	const [editedData, setEditedData] = useState(null);
	const [loading, setloading] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [loader, setLoader] = useState(true);

	const toaster = useRef();

	useEffect(() => {
		if (hasParam) {
			Api('GET', `cms/user/view/${hasParam}`)
				.then((res) => {
					setEditedData(res.data.data);
					setLoader(false);
				})
				.catch((err) => {
					setLoader(false);
				});
		} else {
			setLoader(false);
		}
	}, []);

	const submitFormHandler = (data, action) => {
		if (action === 'add') {
			let params = {
				firstName: data.firstName,
				lastName: data.lastName,
				email: data.email,
				role: data.role,
				password: data.password,
				countryCode: data.countryCode,
				phoneNumber: data.phoneNumber,
			};
			setloading(true);
			setDisabled(true);
			Api('POST', 'app/user/create', params)
				.then((res) => {
					if (res.status === STATUS_CODES.SUCCESS) {
						if (toaster.current) toaster.current.success(res.data.message);
						setTimeout((res) => {
							props.history.push('/user');
						}, 1000);
						setloading(false);
						setDisabled(false);
					} else {
						if (toaster.current) toaster.current.error(res.data.message);
						setloading(false);
						setDisabled(false);
					}
				})
				.catch((err) => {
					if (toaster.current) toaster.current.error(err.response);
					setloading(false);
					setDisabled(false);
				});
		} else if (action === 'edit') {
			let params = {
				id: data.id,
				firstName: data.firstName,
				lastName: data.lastName,
				email: data.email,
				role: data.role,
				countryCode: data.countryCode,
				phoneNumber: data.phoneNumber,
			};
			setloading(true);
			setDisabled(true);
			Api('PUT', `cms/user/edit/${hasParam}`, params)
				.then((res) => {
					if (res.status === STATUS_CODES.SUCCESS) {
						if (toaster.current) toaster.current.success(res.data.message);
						props.history.push('/user');
						setloading(false);
						setDisabled(false);
					} else {
						if (toaster.current) toaster.current.error(res.data.message);
						setloading(false);
						setDisabled(false);
					}
				})
				.catch((err) => {
					if (toaster.current) toaster.current.error(err.response);
					setloading(false);
					setDisabled(false);
				});
		}
	};

	return (
		<UserWrapper {...props}>
			<div className="pos-relative">
				<PageTitle
					title="sidebar.user"
					className="plr-15"
					breadCrumb={[
						{
							name: 'sidebar.user',
						},
						{
							name: hasParam ? 'action.edit' : 'action.add',
						},
					]}
				/>
				<div className="back-icon fs-15 demi-bold-text cursor-pointer" onClick={() => props.history.push('/user')}>
					<i className="fas fa-step-backward"></i> Back
				</div>
			</div>
			<div className="plr-15">
				<div className="roe-card-style mtb-15">
					<div className="roe-card-header module-header">
						<div className="flex-1 fs-16 demi-bold-text">
							<span className="hash"># </span> {hasParam ? 'Edit' : 'Add'} User
						</div>
					</div>
					<div className="roe-card-body">
						<UserForm
							onSubmit={submitFormHandler}
							editedData={editedData}
							action={hasParam ? 'edit' : 'add'}
							loading={loading}
							disabled={disabled}
							loader={loader}
						/>
					</div>
				</div>
				<Toaster ref={toaster} />
			</div>
		</UserWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.themeChanger,
	};
};

export default connect(mapStateToProps, null)(UserEdit);
