import React, { useRef, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import enhancer from './validator';
import Toaster from '../common/Toaster';
import { withRouter } from 'react-router-dom';
import AuthActions from '../../redux/auth/actions';
import { STATUS_CODES } from '../../helper/constant';
import Placeholder from '../../assets/images/Logo.png';
import SmallLoader from '../common/SmallLoader';
import Api from 'helper/Api';

const { admin } = AuthActions;

const EditProfileForm = (props) => {
	const { handleChange, handleBlur, errors, touched, submitCount, onSubmit, setFieldValue, values, action } = props;

	const [imageObj, setImageObj] = useState(null);
	const [uploadImage, setUploadImage] = useState(props.fileName);
	const [loading, setloading] = useState(false);
	const [disabled, setDisabled] = useState(false);

	const toaster = useRef();

	const Error = (props) => {
		const field1 = props.field;
		if ((errors[field1] && touched[field1]) || submitCount > 0) {
			return <span className={props.class ? props.class : 'error-msg'}>{errors[field1]}</span>;
		} else {
			return <span></span>;
		}
	};

	const handleImage = (e, setValue) => {
		if (e.target.files[0]) {
			let fileObj = e.target.files[0];
			setImageObj(fileObj);
			if (fileObj) {
				setValue('profilePic', URL.createObjectURL(fileObj));
				setUploadImage(URL.createObjectURL(fileObj));
			}
		} else {
			setUploadImage(null);
		}
	};

	const handleSubmit = () => {
		let { values, isValid, handleSubmit } = props;
		if (isValid) {
			onSubmit(values, action);
			const params = {
				firstName: values.firstName,
				lastName: values.lastName,
				profilePic: imageObj,
			};
			const formData = new FormData();
			for (let key in params) {
				if (params[key]) formData.append(key, params[key]);
			}
			setloading(true);
			setDisabled(true);
			Api('PUT', 'cms/admin/update/details', formData)
				.then((res) => {
					if (res.status === STATUS_CODES.SUCCESS) {
						if (toaster.current) toaster.current.success(res.data.message);
						props.admin({
							firstName: values.firstName,
							lastName: values.lastName,
							profilePic: res.data.data.profilePic,
							email: res.data.data.email,
							role: res.data.data.role,
						});
						setTimeout(() => {
							props.history.push('/dashboard');
							setloading(false);
							setDisabled(false);
						}, 1000);
					} else {
						setloading(false);
						setDisabled(false);
						if (toaster.current) toaster.current.error(res.data.message);
					}
				})
				.catch((err) => {
					setloading(false);
					setDisabled(false);
					if (toaster.current) toaster.current.error(err.response);
				});
		}
		handleSubmit();
	};

	return (
		<div>
			<form>
				<div className="form-group">
					<label className="fs-16 medium-text">First Name</label>
					<input
						type="text"
						className="form-control react-form-input"
						id="firstName"
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.firstName}
						placeholder="First Name"
					/>
					<Error field="firstName" />
				</div>

				<div className="form-group">
					<label className="fs-16 medium-text">Last Name</label>
					<input
						type="text"
						className="form-control react-form-input"
						id="lastName"
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.lastName}
						placeholder="Last Name"
					/>
					<Error field="lastName" />
				</div>

				<div className="form-group">
					<label className="fs-16 medium-text">Email</label>
					<input
						type="email"
						className="form-control react-form-input"
						id="email"
						disabled
						onChange={handleChange}
						onBlur={handleBlur}
						value={props.email}
						placeholder="Email"
					/>
					<Error field="email" />
				</div>
				<div>
					<div className="form-group">
						<label className="fs-16 medium-text">Profile Image</label>
						<br />
						<div className="file-upload">
							<label className="c-btn c-primary form-button fs-16 demi-bold-text mt-15">
								Upload image
								<input
									id="profilePic"
									className="file-upload__input"
									name="file-upload"
									type="file"
									accept="image/jpg,image/png,image/jpeg"
									onChange={(e) => handleImage(e, setFieldValue)}
									onBlur={handleBlur}
								/>
							</label>
						</div>
						<br />
						{
							<img
								src={uploadImage ? uploadImage : values.profilePic ? values.profilePic : Placeholder}
								alt=""
								className="mtb-15"
								style={{
									width: '200px',
									borderRadius: '4%',
									height: '150px',
									background: '#404040',
								}}
							/>
						}
						<br />
						<Error field="profilePic" />
					</div>
				</div>
				<div>
					{!loading && (
						<button
							type="button"
							className="c-btn c-info form-button fs-16 demi-bold-text"
							style={{ maxWidth: '125px' }}
							onClick={() => handleSubmit()}
							disabled={disabled}
						>
							Submit
						</button>
					)}
					{loading && <SmallLoader />}
				</div>
			</form>
			<Toaster ref={toaster} />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		firstName: state.auth.firstName,
		lastName: state.auth.lastName,
		email: state.auth.email,
		profilePic: state.auth.profilePic,
		id: state.auth.id,
		...state.themeChanger,
	};
};
export default compose(withRouter, connect(mapStateToProps, { admin }), enhancer)(EditProfileForm);
