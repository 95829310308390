import React, { useState, useEffect, useRef } from 'react';
import ReactTable from 'react-table';
import Button from 'components/button/Button';
import Pagination from 'components/common/Pagination';
import 'react-table/react-table.css';
import { withRouter } from 'react-router';
import Loader from '../common/Loader';
import moment from 'moment';
import Toaster from '../common/Toaster';
import { TABLE } from '../../helper/constant';
import Api from 'helper/Api';
import CommonModel from '../common/CommonModel';
import View from './ViewModal';
import AuthActions from '../../redux/auth/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import RoyTooltip from 'components/common/RoyTooltip';
const { admin } = AuthActions;

const NotificationTable = (props) => {
	const { role } = props;
	const columns = [
		{
			Header: 'Title',
			accessor: 'title',
			className: 'text-center',
			filterable: false,
			headerClassName: 'react-table-header-class',
		},
		{
			Header: 'Content',
			accessor: 'content',
			className: 'text-center',
			filterable: false,
			headerClassName: 'react-table-header-class',
		},
		{
			Header: 'Date and Time',
			accessor: 'createdAt',
			className: 'text-center',
			filterable: false,
			headerClassName: 'react-table-header-class',
			Cell: (props) => (
				<div>
					{moment(props.original.createdAt)
						.utc()
						.format('MMM-DD-YYYY')}{' '}
					at{' '}
					{moment(props.original.createdAt)
						.utc()
						.format('HH:mm:ss')}
				</div>
			),
		},
		{
			Header: 'Action',
			accessor: 'action',
			className: 'text-center',
			headerClassName: 'react-table-header-class',
			sortable: false,
			filterable: false,
			width: 200,
			Cell: (props) => (
				<div className="react-action-class">
					<RoyTooltip id={`view-${props.original.id}`} title={'View'} placement="left">
						<Button
							className="c-btn c-primary mr-10"
							style={{ minWidth: '50px' }}
							onClick={() => {
								setView(true);
								setModal(true);
								setData(props.original);
							}}
							id={`view-${props.original.id}`}
						>
							<div className="fs-14 medium-text">
								<i className="fas fa-info" />
							</div>
						</Button>
					</RoyTooltip>
				</div>
			), // Custom cell components!
		},
	];

	const [tblData, settblData] = useState();
	const [loader, setLoader] = useState(false);
	const [pages, setPages] = useState(0);
	const [activePage, setActivePage] = useState(0);
	const [searchText, setSearchText] = useState('');
	const [field, setField] = useState('createdAt');
	const [sortBy, setSortBy] = useState('DESC');
	const [modal, setModal] = useState(false);
	const [data, setData] = useState({});
	const [view, setView] = useState(false);
	// const [deleteModal, setDeleteModal] = useState(false);

	const toaster = useRef();

	const {
		sidebarTheme: { activeColor },
	} = props;

	useEffect(() => {
		getList(field, sortBy, searchText, activePage + 1);
		setPages(10);
	}, []);

	const onChangePageGetData = (state) => {
		if (state.page !== activePage) {
		}
	};

	const formAction = (action, data = null) => {
		// Here you can view the data and make forward action for edit data
		if (action === 'add') {
			props.history.push('/notification/add');
		}
	};

	function getList(field = 'createdAt', sortBy = 'DESC', search = '', page = '1') {
		const params = {
			sort_by: field,
			order: sortBy,
			search: search,
			page: page,
		};
		setLoader(true);

		Api('POST', 'cms/notification/list', params)
			.then((res) => {
				if (res.status === 200) {
					settblData(res.data.data.notificationList.rows);
					setPages(Math.ceil(res.data.data.notificationList.count / TABLE.LIMIT));
					setLoader(false);
				} else {
					setLoader(false);
					if (toaster.current) toaster.current.error(res.data.message);
				}
			})
			.catch((err) => {
				setLoader(false);
				if (toaster.current) toaster.current.error(err.response);
			});
	}

	return (
		<div>
			<div className="plr-15">
				<div className="roe-card-style mtb-15">
					<div className="roe-card-header module-header">
						<div className="flex-1 fs-16 demi-bold-text" />
						<div className="mr-10">
							<input
								value={searchText}
								onChange={(e) => {
									setSearchText(e.target.value);
									const search = e.target.value;
									setLoader(true);
									setActivePage(0);
									setTimeout(() => {
										getList(field, sortBy, search, 1);
									}, 500);
								}}
								type="text"
								placeholder="Search by Title..."
								className="fs-14 medium-text plr-10 form-control react-form-input"
							/>
						</div>
						<div>
							{role === 1 ? (
								<Button
									className="c-btn ma-5"
									onClick={() => formAction('add')}
									style={{
										backgroundColor: activeColor,
										color: 'white',
									}}
								>
									<i className="fas fa-plus mr-10" />
									Send Notification
								</Button>
							) : (
								''
							)}
						</div>
					</div>
					<div className="roe-card-body">
						<ReactTable
							style={{
								border: 'none',
								boxShadow: 'none',
							}}
							data={tblData}
							columns={columns}
							defaultPageSize={10}
							filterable
							minRows={2}
							manual
							defaultFilterMethod={(filter, row) => {
								const id = filter.pivotId || filter.id;

								return row[id] !== undefined
									? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
									: true;
							}}
							className="-striped -highlight custom-react-table-theme-class"
							pages={pages}
							page={activePage}
							PaginationComponent={Pagination}
							loading={loader}
							LoadingComponent={Loader}
							onFetchData={onChangePageGetData}
							onPageChange={(pageIndex) => {
								setActivePage(pageIndex);
								getList(field, sortBy, searchText, pageIndex + 1);
							}}
							onSortedChange={(sortProperties) => {
								const sort = sortProperties[0].desc ? 'DESC' : 'ASC';
								setSortBy(sort);
								setActivePage(0);
								setField(sortProperties[0].id);
								getList(sortProperties[0].id, sort, searchText, 1);
							}}
						/>
					</div>
					{view && (
						<CommonModel
							modal={modal}
							size="lg"
							title="Notification information"
							style={{ maxWidth: '800px', width: '80%' }}
							children={
								<View
									data={data}
									toggle={() => {
										setModal(false);
									}}
								/>
							}
						/>
					)}
				</div>
			</div>
			<Toaster ref={toaster} />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		role: state.auth.role,
		id: state.auth.id,
		...state.themeChanger,
	};
};
export default compose(withRouter, connect(mapStateToProps, { admin }))(NotificationTable);
