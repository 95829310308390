import React, { useEffect, useState, useRef } from 'react';
import UserWrapper from './style';
import PageTitle from 'components/common/PageTitle';
import { connect } from 'react-redux';
import Toaster from '../../components/common/Toaster';
import NotificationForm from '../../components/pushnotification/NotificationForm';
import Api from 'helper/Api';

const NotificationEdit = (props) => {
	const hasParam = props.match.params.id;

	const [editedData, setEditedData] = useState(null);
	const [loading, setloading] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [loader, setLoader] = useState(true);

	const toaster = useRef();

	useEffect(() => {
		if (hasParam) {
			Api('GET', `cms/notification/view/${hasParam}`)
				.then((res) => {
					setEditedData(res.data.data);
					setLoader(false);
				})
				.catch((err) => {
					setLoader(false);
				});
		} else {
			setLoader(false);
		}
	}, []);

	const submitFormHandler = (data, action) => {
		if (action === 'add') {
			let params = {
				title: data.title,
				content: data.content,
			};
			// const formData = new FormData();
			// for (let key in params) {
			// 	formData.append(key, params[key]);
			// }
			setloading(true);
			setDisabled(true);
			Api('POST', 'cms/notification/send', params)
				.then((res) => {
					if (res.status === 200) {
						if (toaster.current) toaster.current.success(res.data.message);
						setTimeout((res) => {
							props.history.push('/notification');
						}, 1000);
						setloading(false);
						setDisabled(false);
					} else {
						if (toaster.current) toaster.current.error(res.data.message);
						setloading(false);
						setDisabled(false);
					}
				})
				.catch((err) => {
					if (toaster.current) toaster.current.error(err.response);
					setloading(false);
					setDisabled(false);
				});
		}
		// else if (action === 'edit') {
		// 	let params = {
		// 		id: data.id,
		// 		filterName: data.filterName,
		// 		colorCode: data.colorCode,
		// 		fileName: data.fileName,
		// 	};
		// 	const formData = new FormData();
		// 	for (let key in params) {
		// 		if (params[key]) formData.append(key, params[key]);
		// 	}
		// 	setloading(true);
		// 	setDisabled(true);
		// 	Api('PUT', `cms/notification/update/${hasParam}`, formData)
		// 		.then((res) => {
		// 			if (res.status === 200) {
		// 				if (toaster.current) toaster.current.success(res.data.message);
		// 				setTimeout((res) => {
		// 					props.history.push('/notification');
		// 				}, 1000);
		// 				setloading(false);
		// 				setDisabled(false);
		// 			} else {
		// 				if (toaster.current) toaster.current.error(res.data.message);
		// 				setloading(false);
		// 				setDisabled(false);
		// 			}
		// 		})
		// 		.catch((err) => {
		// 			if (toaster.current) toaster.current.error(err.response);
		// 			setloading(false);
		// 			setDisabled(false);
		// 		});
		// }
	};

	return (
		<UserWrapper {...props}>
			<div className="pos-relative">
				<PageTitle
					title="sidebar.notification"
					className="plr-15"
					breadCrumb={[
						{
							name: 'sidebar.notification',
						},
						{
							name: hasParam ? 'action.edit' : 'action.add',
						},
					]}
				/>
				<div
					className="back-icon fs-15 demi-bold-text cursor-pointer"
					onClick={() => props.history.push('/notification')}
				>
					<i className="fas fa-step-backward"></i> Back
				</div>
			</div>
			<div className="plr-15">
				<div className="roe-card-style mtb-15">
					<div className="roe-card-header module-header">
						<div className="flex-1 fs-16 demi-bold-text">
							<span className="hash"># </span> {hasParam ? 'Edit' : 'Add'} Notification
						</div>
					</div>
					<div className="roe-card-body">
						<NotificationForm
							onSubmit={submitFormHandler}
							editedData={editedData}
							action={hasParam ? 'edit' : 'add'}
							loading={loading}
							disabled={disabled}
							loader={loader}
						/>
					</div>
				</div>
				<Toaster ref={toaster} />
			</div>
		</UserWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.themeChanger,
	};
};

export default connect(mapStateToProps, null)(NotificationEdit);
