import React from 'react';
// import Placeholder from '../../assets/images/Logo.png';
import NotificationWrapper from './Notification.Style';
const viewModal = (props) => {
	let data = props.data;
	return (
		<NotificationWrapper>
			<div className="wp-100">
				<div className="fs-15 demi-bold-text cursor-pointer float-right" onClick={() => props.toggle()}>
					<i className="fa fa-times"></i>
				</div>
				<div className=" fs-20 font-weight-bolder">Notification information</div>
				<hr />
				<div>
					<div className="notification-heading">Notification Title</div>
					<div className="notification-title">{`${data.title}`}</div>
				</div>
			</div>
			<br />

			<div className="notification-heading">Notification Content</div>
			<div className="notification-content">{`${data.content}`}</div>
		</NotificationWrapper>
	);
};

export default viewModal;
