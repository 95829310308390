export const sidebarData = [
	{
		name: 'sidebar.dashboard',
		iconClass: 'fas fa-chalkboard',
		routepath: '/dashboard',
	},
	{
		name: 'sidebar.admin',
		iconClass: 'fas fa-user',
		routepath: '/admin',
	},
	{
		name: 'sidebar.staticpage',
		iconClass: 'fas fa-clone',
		routepath: '/static-page',
	},
	{
		name: 'sidebar.user',
		iconClass: 'fa fa-user-circle',
		routepath: '/user',
	},
	{
		name: 'sidebar.filter',
		iconClass: 'fas fa-camera',
		routepath: '/filter',
	},
	{
		name: 'sidebar.event',
		iconClass: 'far fa-calendar-alt',
		routepath: '/event',
	},
	{
		name: 'sidebar.notification',
		iconClass: 'fas fa-bell',
		routepath: '/notification',
	},
	{
		name: 'sidebar.sharecontent',
		iconClass: 'fas fa-envelope',
		routepath: '/share',
	},
	{
		name: 'sidebar.static-content',
		iconClass: 'fas fa-cloud-upload-alt',
		routepath: '/static-content',
	},
];
export const sidebarDataSubAdmin = [
	{
		name: 'sidebar.dashboard',
		iconClass: 'fas fa-chalkboard',
		routepath: '/dashboard',
	},
	{
		name: 'sidebar.admin',
		iconClass: 'far fa-user',
		routepath: '/admin',
	},
	{
		name: 'sidebar.staticpage',
		iconClass: 'far fa-clone',
		routepath: '/static-page',
	},
	{
		name: 'sidebar.user',
		iconClass: 'fa fa-user-circle',
		routepath: '/user',
	},
	{
		name: 'sidebar.filter',
		iconClass: 'fas fa-camera',
		routepath: '/filter',
	},
	{
		name: 'sidebar.event',
		iconClass: 'far fa-calendar-alt',
		routepath: '/event',
	},
	// {
	// 	name: 'sidebar.sharecontent',
	// 	iconClass: 'far fa-calendar-alt',
	// 	routepath: '/event',
	// },
];

// Comments:::::::

//  If you want one level child then look below example

/*
  {
    name: 'sidebar.forms',
    iconClass: 'fab fa-wpforms',
    child: [
      {
        listname: 'sidebar.regularforms',
        routepath: '/regularform',
        shortname: 'RF'
      }
    ]
  }
*/

//  If you want Second level child then look below example

/*
   {
      name: 'sidebar.pages',
      iconClass: 'fas fa-print',
      child: [
        {
          listname: 'sidebar.authentication',
          iconClass: 'fas fa-user',
          child: [
            {
              listname: 'sidebar.login',
              routepath: '/login',
              shortname: 'L'
            },
          ]
        }
      ]
    }
*/
