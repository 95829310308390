import authAction from './actions';

const initState = {
	isLogin: false,
	accessToken: null,
	firstName: localStorage.getItem('firstName'),
	lastName: localStorage.getItem('lastName'),
	email: null,
	key: null,
	profilePic: null,
	modules: null,
	role: null,
};
export default function rootReducer(state = initState, action) {
	switch (action.type) {
		case authAction.LOGIN:
			return {
				...state,
				isLogin: action.isLogin,
				accessToken: action.accessToken,
				firstName: action.firstName,
				lastName: action.lastName,
				email: action.email,
				key: action.key,
				id: action.id,
				profilePic: action.profilePic,
				modules: action.modules,
				userType: action.userType,
				role: action.role,
			};
		case authAction.LOGOUT:
			return {
				...state,
				isLogin: action.isLogin,
				accessToken: null,
			};
		case authAction.ADMIN:
			return {
				...state,
				firstName: action.firstName,
				lastName: action.lastName,
				email: action.email,
				profilePic: action.profilePic,
				role: action.role,
			};
		default:
			return state;
	}
}
