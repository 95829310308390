import { withFormik } from 'formik';
import * as Yup from 'yup';
const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
const formikEnhancer = withFormik({
	validationSchema: Yup.object().shape({
		firstName: Yup.string()
			.trim()
			.required('This field is required'),
		lastName: Yup.string()
			.trim()
			.required('This field is required'),
		email: Yup.string()
			.email('Please enter valid email')
			.trim()
			.required('This field is required'),
		role: Yup.string()
			.trim()
			.required('This field is required'),
		password: Yup.string().when('isEdit', {
			is: false,
			then: Yup.string().required('This field is required'),
		}),
		confirmPassword: Yup.string().when('isEdit', {
			is: false,
			then: Yup.string().required('This field is required'),
		}),
		phoneNumber: Yup.string()
			.matches(phoneRegExp, 'Phone number is not valid')
			.when('isEdit', {
				is: false,
				then: Yup.string().required('This field is required'),
			}),
	}),
	validate: (values) => {
		const errors = {};
		if (values.password && values.password !== values.confirmPassword) {
			errors.confirmPassword = 'Password not matched';
		}
		return errors;
	},
	mapPropsToValues: (props) => ({
		id: props && props.editedData && props.editedData.id ? props.editedData.id : '',
		firstName: props && props.editedData && props.editedData.firstName ? props.editedData.firstName : '',
		lastName: props && props.editedData && props.editedData.lastName ? props.editedData.lastName : '',
		email: props && props.editedData && props.editedData.email ? props.editedData.email : '',
		role: props && props.editedData && props.editedData.role ? props.editedData.role : '',
		phoneNumber: props && props.editedData && props.editedData.phoneNumber ? props.editedData.phoneNumber : '',
	}),
	handleSubmit: (values) => {},
	displayName: 'CustomValidationForm',
	enableReinitialize: true,
});

export default formikEnhancer;
