import React from 'react';
import PageWrapper from './style';
import PageTitle from 'components/common/PageTitle';
import { connect } from 'react-redux';
import FilterTable from '../../components/filter/FilterTable';

const Filter = (props) => {
	return (
		<PageWrapper {...props}>
			<PageTitle title="sidebar.filter" className="plr-15" />
			<div className="user-tbl">
				<FilterTable {...props} />
			</div>
		</PageWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.themeChanger,
	};
};

export default connect(mapStateToProps, null)(Filter);
