import React from "react";
import PageWrapper from "./style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import UserTable from "../../components/user/UserTable";

const User = (props) => {
  return (
    <PageWrapper {...props}>
      <PageTitle title="sidebar.user" className="plr-15" />
      <div className="user-tbl">
        <UserTable {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
  };
};

export default connect(mapStateToProps, null)(User);
