import React, { useState, useEffect, useRef } from 'react';
import ReactTable from 'react-table';
import Button from 'components/button/Button';
import Pagination from 'components/common/Pagination';
import 'react-table/react-table.css';
import { withRouter } from 'react-router';
import Loader from '../common/Loader';
import moment from 'moment';
import Toaster from '../common/Toaster';
import { STATUS_CODES, TABLE } from '../../helper/constant';
import Api from 'helper/Api';
import Placeholder from '../../assets/images/Logo.png';
import RoyTooltip from 'components/common/RoyTooltip';
import { compose } from 'redux';
import { connect } from 'react-redux';
import AuthActions from '../../redux/auth/actions';

const { admin } = AuthActions;

const AdminTable = (props) => {
	const { role } = props;
	const columns = [
		{
			Header: 'Image',
			accessor: 'profilePic',
			className: 'text-center',
			sortable: false,
			filterable: false,
			headerClassName: 'react-table-header-class',
			Cell: (props) => (
				<div>
					<img
						src={props.original.profilePic ? `${props.original.profilePic}` : Placeholder}
						alt="error"
						style={{ height: '30px', width: '30px', borderRadius: '50%' }}
					/>
				</div>
			),
		},
		{
			Header: 'Name',
			accessor: 'firstName',
			className: 'text-center',
			filterable: false,
			headerClassName: 'react-table-header-class',
			Cell: (props) => <div>{`${props.original.firstName} ${props.original.lastName}`}</div>,
		},
		{
			Header: 'Email',
			accessor: 'email',
			className: 'text-center',
			filterable: false,
			headerClassName: 'react-table-header-class',
		},
		{
			Header: 'Role',
			accessor: 'role',
			className: 'text-center',
			filterable: false,
			headerClassName: 'react-table-header-class',
			Cell: (props) => <div>{`${props.original.role}` === '1' ? 'Admin' : 'Sub-Admin'}</div>,
		},
		{
			Header: 'Date',
			accessor: 'createdAt',
			className: 'text-center',
			filterable: false,
			headerClassName: 'react-table-header-class',
			Cell: (props) => <div>{moment(props.original.createdAt).format('MMM-DD-YYYY')}</div>,
		},
		{
			Header: 'Status',
			accessor: 'status',
			className: 'text-center',
			headerClassName: 'react-table-header-class',
			sortable: false,
			filterable: false,
			Cell: (props) => (
				<div className="react-action-class">
					{role === 1 ? (
						props.original.role === 2 ? (
							props.original.isActive ? (
								<div id={`active-${props.original.id}`}>
									<RoyTooltip id={`act-${props.original.id}`} title={'Click to Deactivate'} placement="left">
										<Button
											className="c-btn c-success mr-10"
											style={{ minWidth: '50px' }}
											onClick={() => activeInactiveStatusHandler(props.original.id)}
											id={`act-${props.original.id}`}
										>
											<div className="fs-14  ">
												<i className="fa fa-check" aria-hidden="true"></i>
											</div>
										</Button>
									</RoyTooltip>
								</div>
							) : (
								<div id={`deactive-${props.original.id}`}>
									<RoyTooltip id={`dact-${props.original.id}`} title={'Click to Activate'} placement="left">
										<Button
											style={{ minWidth: '50px' }}
											className="c-btn c-danger mr-10"
											onClick={() => activeInactiveStatusHandler(props.original.id)}
											id={`dact-${props.original.id}`}
										>
											<div>
												<div className="fs-14" id="hi">
													<i class="fa fa-times" aria-hidden="true"></i>
												</div>
											</div>
										</Button>
									</RoyTooltip>
								</div>
							)
						) : (
							'-'
						)
					) : (
						'-'
					)}
				</div>
			), // Custom cell components!
		},
		{
			Header: 'Action',
			accessor: 'action',
			className: 'text-center',
			headerClassName: 'react-table-header-class',
			sortable: false,
			filterable: false,
			width: 160,
			Cell: (props) => (
				<div className="react-action-class">
					{role === 1 ? (
						props.original.role === 2 ? (
							<RoyTooltip id={`edit-${props.original.id}`} title={'Edit'} placement="left">
								<Button
									style={{ minWidth: '50px' }}
									className="c-btn c-success mr-10"
									onClick={() => formAction('edit', props.original)}
									id={`edit-${props.original.id}`}
								>
									<div className="fs-14 medium-text">
										<i className="fas fa-edit" />
									</div>
								</Button>
							</RoyTooltip>
						) : (
							'-'
						)
					) : (
						'-'
					)}
				</div>
			), // Custom cell components!
		},
	];

	const [tblData, settblData] = useState([]);
	const [loader, setLoader] = useState(false);
	const [pages, setPages] = useState(0);
	const [activePage, setActivePage] = useState(0);
	const [field, setField] = useState('createdAt');
	const [sortBy, setSortBy] = useState('DESC');

	const toaster = useRef();

	const {
		sidebarTheme: { activeColor },
	} = props;

	useEffect(() => {
		getList(field, sortBy, activePage + 1);
		setPages(1);
	}, []);

	const activeInactiveStatusHandler = (id) => {
		setLoader(true);
		Api('GET', `cms/admin/change/status/${id}`)
			.then((response) => {
				if (response.status === STATUS_CODES.SUCCESS) {
					if (toaster.current) toaster.current.success(response.data.message);
					getList(field, sortBy, activePage + 1);
					setLoader(false);
				}
			})
			.catch((error) => {
				if (toaster.current) toaster.current.error(error);
				setLoader(false);
			});
	};

	const onChangePageGetData = (state) => {
		if (state.page !== activePage) {
		}
	};

	const formAction = (action, data = null) => {
		// Here you can view the data and make forward action for edit data
		if (action === 'add') {
			props.history.push('/admin/add');
		} else if (action === 'edit') {
			props.history.push(`/admin/edit/${data.id}`);
		}
	};

	function getList(field = 'createdAt', sortBy = 'DESC', search = '', page = '1') {
		const params = {
			sort_by: field,
			order: sortBy,
			search: search,
			page: page,
		};
		setLoader(true);

		Api('POST', 'cms/admin/list', params)
			.then((res) => {
				if (res.status === STATUS_CODES.SUCCESS) {
					settblData(res.data.data.rows);
					setPages(Math.ceil(res.data.data.count / TABLE.LIMIT));
					setLoader(false);
				} else {
					setLoader(false);
					if (toaster.current) toaster.current.error(res.data.message);
				}
			})
			.catch((err) => {
				setLoader(false);
				if (toaster.current) toaster.current.error(err.response);
			});
	}

	return (
		<div>
			<div className="plr-15">
				<div className="roe-card-style mtb-15">
					<div className="roe-card-header module-header">
						<div className="flex-1 fs-16 demi-bold-text" />
						<div>
							{role === 1 ? (
								<Button
									className="c-btn ma-5"
									onClick={() => formAction('add')}
									style={{
										backgroundColor: activeColor,
										color: 'white',
									}}
								>
									<i className="fas fa-plus mr-10" />
									Add Admin
								</Button>
							) : (
								''
							)}
						</div>
					</div>
					<div className="roe-card-body">
						<ReactTable
							style={{
								border: 'none',
								boxShadow: 'none',
							}}
							data={tblData}
							columns={columns}
							defaultPageSize={10}
							filterable
							minRows={2}
							manual
							defaultFilterMethod={(filter, row) => {
								const id = filter.pivotId || filter.id;

								return row[id] !== undefined
									? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
									: true;
							}}
							className="-striped -highlight custom-react-table-theme-class"
							pages={pages}
							page={activePage}
							PaginationComponent={Pagination}
							loading={loader}
							LoadingComponent={Loader}
							onFetchData={onChangePageGetData}
							onPageChange={(pageIndex) => {
								setActivePage(pageIndex);
								getList(field, sortBy, pageIndex + 1);
							}}
							onSortedChange={(sortProperties) => {
								const sort = sortProperties[0].desc ? 'DESC' : 'ASC';
								setSortBy(sort);
								setActivePage(0);
								setField(sortProperties[0].id);
								getList(sortProperties[0].id, sort, 1);
							}}
						/>
					</div>
				</div>
			</div>
			<Toaster ref={toaster} />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		firstName: state.auth.firstName,
		lastName: state.auth.lastName,
		email: state.auth.email,
		profilePic: state.auth.profilePic,
		role: state.auth.role,
		id: state.auth.id,
		...state.themeChanger,
	};
};
export default compose(withRouter, connect(mapStateToProps, { admin }))(AdminTable);
