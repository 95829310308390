import React, { useEffect } from 'react';
import { compose } from 'redux';
import { Input } from 'reactstrap';
import enhancer from './Validator';
import SmallLoader from '../common/SmallLoader';
import Loader from 'components/common/Loader';

const UserForm = (props) => {
	const { handleChange, handleBlur, errors, touched, submitCount, onSubmit, values, action, setFieldValue } = props;

	useEffect(() => {
		if (action === 'edit') {
			setFieldValue('isEdit', true);
		} else {
			setFieldValue('isEdit', false);
		}
	}, []);

	const Error = (props) => {
		const field1 = props.field;
		if ((errors[field1] && touched[field1]) || submitCount > 0) {
			return <span className={props.class ? props.class : 'error-msg'}>{errors[field1]}</span>;
		} else {
			return <span />;
		}
	};

	const handleSubmit = () => {
		let { values, isValid, handleSubmit } = props;

		if (isValid) {
			onSubmit(values, action);
		}
		handleSubmit();
	};

	return (
		<div>
			{!props.loader ? (
				<form>
					<div className="form-group">
						<label className="fs-16 medium-text">First name</label>
						<input
							type="text"
							className="form-control react-form-input"
							id="firstName"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.firstName}
							placeholder="First Name"
						/>
						<Error field="firstName" />
					</div>
					<div className="form-group">
						<label className="fs-16 medium-text">Last name</label>
						<input
							type="text"
							className="form-control react-form-input"
							id="lastName"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.lastName}
							placeholder="Last Name"
						/>
						<Error field="lastName" />
					</div>

					<div className="form-group">
						<label className="fs-16 medium-text">Email</label>
						<input
							type="text"
							className="form-control react-form-input"
							id="email"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.email}
							placeholder="Email"
							disabled={values.id ? true : false}
						/>
						<Error field="email" />
					</div>
					<div className="form-group">
						<label className="fs-16 medium-text">Role</label>
						{action === 'add' ? (
							<Input
								type="select"
								name="role"
								id="role"
								value={values.role || 'select_role'}
								placeholder="Role"
								onChange={handleChange}
								onBlur={handleBlur}
							>
								<option value={'select_role'} selected disabled>
									Select role
								</option>
								<option value="1">Super User</option>
								<option value="2">Normal User</option>
							</Input>
						) : (
							<Input
								type="select"
								name="role"
								id="role"
								value={values.role || 'select_role'}
								placeholder="Role"
								onChange={handleChange}
								onBlur={handleBlur}
							>
								<option value={'select_role'} selected disabled>
									Select role
								</option>
								<option value="1">Super User</option>
								<option value="2">Normal User</option>
							</Input>
						)}
						<Error field="role" />
					</div>

					{!values.id && (
						<div>
							<div className="form-group">
								<label className="fs-16 medium-text">Password</label>
								<input
									type="password"
									className="form-control react-form-input"
									id="password"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.password}
									placeholder="Password"
								/>
								<Error field="password" />
							</div>
							<div className="form-group">
								<label className="fs-16 medium-text">Confirm Password</label>
								<input
									type="password"
									className="form-control react-form-input"
									id="confirmPassword"
									onChange={handleChange}
									onBlur={handleBlur}
									value={values.confirmPassword}
									placeholder="Confirm Password"
								/>
								<Error field="confirmPassword" />
							</div>
						</div>
					)}
					<div className="form-group">
						<label className="fs-16 medium-text">Phone Number</label>

						<div className="phoneNumber">
							<input
								className="form-control react-form-input"
								type="text"
								id="phoneNumber"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.phoneNumber}
								placeholder="Phone Number"
							/>
							<Error field="phoneNumber" />
						</div>
					</div>
					<div>
						<div>
							{!props.loading && (
								<button
									type="button"
									className="c-btn c-info form-button fs-16 demi-bold-text"
									style={{ maxWidth: '125px' }}
									onClick={() => handleSubmit()}
									disabled={props.disabled}
								>
									Submit
								</button>
							)}
							{props.loading && <SmallLoader />}
						</div>
					</div>
				</form>
			) : (
				<Loader loading={props.loader} />
			)}
		</div>
	);
};

export default compose(enhancer)(UserForm);
