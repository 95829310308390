import styled from 'styled-components';

const EventWrapper = styled.div`
	.media-style {
		flex: 0 0 22%;
		border-radius: 4%;
		height: 180p;
		width: 22%;
		background: #404040;
		margin-right: 10px;
		margin-left: 10px;
		cursor: pointer;
	}

	.overlay-style {
		width: 140px;
		border-radius: 4%;
		border: 1px solid black;
		height: 180px;
		background: whitesmoke;
		margin-right: 10px;
		margin-left: 10px;
		cursor: pointer;
	}
	.text-heading {
		font-weight: bolder;
		text-align: left;
	}
	.disclaimer-heading {
		font-weight: bolder;
		text-align: center;
		margin: 17px;
	}
	.disclaimer-content {
		text-align: justify;
		text-justify: inter-character;
		margin: 20px;
	}
	.options {
		font-weight: normal;
	}
	.text-not-configured {
		margin-bottom: 10px;
		margin-left: 25px;
		margin-top: 10px;
	}
	.filter-list {
		margin-left: 20px;
	}
	.topic {
		margin-top: 20px;
	}
`;
export default EventWrapper;
