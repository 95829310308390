import React from "react";
import PageWrapper from "./style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import AdminTable from "../../components/admin/AdminTable";

const Admin = (props) => {
  return (
    <PageWrapper {...props}>
      <PageTitle title="sidebar.admin" className="plr-15" />
      <div className="user-tbl">
        <AdminTable {...props} />
      </div>
    </PageWrapper>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state.themeChanger,
  };
};

export default connect(mapStateToProps, null)(Admin);
