import React, { useState } from 'react';
import { compose } from 'redux';
import enhancer from './Validator';
import SmallLoader from '../common/SmallLoader';
import Loader from 'components/common/Loader';
// import { ImageResolution } from '../../helper/constant';
import uploadImagePicture from '../../assets/images/lut-placeholder.png';
import { ChromePicker } from 'react-color';

const FilterForm = (props) => {
	const { handleChange, handleBlur, errors, touched, submitCount, onSubmit, values, action, setFieldValue } = props;

	const [uploadImage, setUploadImage] = useState(values.fileName);

	const Error = (props) => {
		const field1 = props.field;
		if ((errors[field1] && touched[field1]) || submitCount > 0) {
			return <span className={props.class ? props.class : 'error-msg'}>{errors[field1]}</span>;
		} else {
			return <span />;
		}
	};

	const handleSubmit = () => {
		let { values, isValid, handleSubmit } = props;

		if (isValid) {
			onSubmit(values, action);
		}
		handleSubmit();
	};

	const handleImage = (e, setValue) => {
		if (e.target.files[0]) {
			let fileObj = e.target.files[0];
			if (fileObj) {
				setValue('fileName', fileObj);
				setUploadImage(URL.createObjectURL(fileObj));
			}
		} else {
			setUploadImage(null);
		}
	};

	return (
		<div>
			{!props.loader ? (
				<form>
					<div className="form-group">
						<label className="fs-16 medium-text">Filter Name</label>
						<input
							type="text"
							className="form-control react-form-input"
							id="filterName"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.filterName}
							placeholder="Filter Name"
						/>
						<Error field="filterName" />
					</div>

					<div>
						<div className="form-group">
							<label className="fs-16 medium-text">Color Code</label>
							<ChromePicker
								disableAlpha
								color={values.colorCode}
								onChange={(color) => setFieldValue('colorCode', color.hex)}
							/>
							<Error field="colorCode" />
							<br />
						</div>
					</div>

					<div>
						<div className="form-group">
							<label className="fs-16 medium-text">Upload Filter File</label>
							<br />
							<p style={{ color: 'red' }}> Please add filter which has LUT size of 17.</p>

							<img
								src={uploadImage ? uploadImage : values.fileName ? values.fileName : uploadImagePicture}
								alt=""
								className="mtb-15"
								style={{
									width: '350px',
									// borderRadius: '4%',
									height: '180px',
									background: '#404040',
								}}
							/>

							<br />
							<div className="file-upload">
								<label className="c-btn c-primary form-button fs-16 demi-bold-text mt-15">
									Upload file
									<input
										id="fileName"
										className="file-upload__input"
										name="file-upload"
										type="file"
										accept="image/jpg,image/png,image/jpeg"
										onChange={(e) => {
											return handleImage(e, setFieldValue);
										}}
										onBlur={handleBlur}
									/>
								</label>
							</div>
							<br />
							<br />
							<Error field="fileName" />
						</div>
					</div>
					<div>
						<div>
							{!props.loading && (
								<button
									type="button"
									className="c-btn c-info form-button fs-16 demi-bold-text"
									style={{ maxWidth: '125px' }}
									onClick={() => handleSubmit()}
									disabled={props.disabled}
								>
									Submit
								</button>
							)}
							{props.loading && <SmallLoader />}
						</div>
					</div>
				</form>
			) : (
				<Loader loading={props.loader} />
			)}
		</div>
	);
};

export default compose(enhancer)(FilterForm);
