import styled from 'styled-components';

const StyleWrapper = styled.div`
	.div-container {
		height: 610px;
		display: flex;
		flex-direction: row;
		/* width: 90vw; */
		max-width: 90vw;
		justify-content: space-between;
		margin-top: 25px;
		margin-left: 14px;
	}
	.dashboard-loader {
		margin-top: 200px;
	}
	.dashboard-count {
		margin-bottom: 20px;
		width: 160px;
		height: 135px;
		transition: box-shadow 0.3s;
		border-radius: 5px;
		border: 1px solid rgb(255 197 197);
		box-shadow: 0 3px 10px rgb(220 109 109 / 20%);
	}
	.parent-dashboard-chart {
		width: 100%;
		margin: 0px 20px;
	}
	.font-count {
		position: relative;
		top: 18px;
		left: 18px;
		color: black;
		font-weight: bolder;
	}
	.user-list-heading {
		margin-bottom: 40px;
	}
	.dashboard-count:hover {
		box-shadow: 0 0 11px #ff7070;
	}
	.chart {
		position: relative;
		margin: auto;
		width: 47vw;
		padding: 10px;
		height: 100%;
		margin-top: 20px;
	}
	.dashboard-user-list {
		margin-bottom: 20px;
		width: 450px;
		height: 600px;
		border: 1px solid rgb(255 197 197);
		transition: box-shadow 0.3s;
		border-radius: 5px;
		box-shadow: 0 3px 10px rgb(220 109 109 / 20%);
	}

	.dashboard-chart {
		position: relative;
		border: 1px solid rgb(255 197 197);
		transition: box-shadow 0.3s;
		height: 290px;
		border-radius: 5px;
		box-shadow: 0 3px 10px rgb(220 109 109 / 20%);
	}
	.event {
		margin-top: 20px;
	}
	.dashboard-chart:hover {
		box-shadow: 0 0 11px #ff7070;
	}
	.font-count-content {
		margin-top: 15px;
		text-align: center;
		font-size: 55px;
	}
	.user-list {
		margin: 10px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		border-bottom: 1px solid #fac5c569;
		height: 44px;
	}
	.dashboard-user-list:hover {
		box-shadow: 0 0 11px #ff7070;
	}
	.avatar {
		margin-right: 10px;
	}
	.user-list-name {
		font-size: 13.5px;
	}
	.user-list-email {
		font-size: 10.5px;
		margin-bottom: 10px;
	}
	.user-list-date {
		font-size: 10.5px;
		margin-right: 10px;
	}
	.user-list-time {
		text-align: right;
	}
	.user-list-container {
		display: flex;
	}
`;

export default StyleWrapper;
