import React from 'react';

const viewModal = (props) => {
	let data = props.adminData;

	const roleArray = [
		{
			roleKey: 1,
			roleName: 'Super User',
		},
		{
			roleKey: 2,
			roleName: 'Normal User',
		},
	];
	let role = [];
	if (data.role) {
		role = roleArray.filter((roleObj) => roleObj.roleKey === data.role);
	} else {
		role = [{ roleKey: 3, roleName: 'No Role Configured' }];
	}
	return (
		<div className="wp-100">
			<div className="fs-15 demi-bold-text cursor-pointer float-right" onClick={() => props.toggle()}>
				<i className="fa fa-times"></i>
			</div>
			<div className=" fs-20 font-weight-bolder">User information</div>
			<hr />
			<div className="row">
				<div className="col-6 pt-4">
					<div>
						<div style={{ fontWeight: 'bolder', textAlign: 'left' }}>First Name</div>
						<div className="text-left">{`${data.firstName}`}</div>
					</div>
				</div>
				<div className="col-6 pt-4">
					<div>
						<div style={{ fontWeight: 'bolder', textAlign: 'left' }}>Last Name</div>
						<div className="text-left">{`${data.lastName}`}</div>
					</div>
				</div>
				<div className="col-6 pt-4">
					<div>
						<div style={{ fontWeight: 'bolder', textAlign: 'left' }}>Email</div>
						<div className="text-left">{`${data.email}`}</div>
					</div>
				</div>
				<div className="col-6 pt-4">
					<div>
						<div style={{ fontWeight: 'bolder', textAlign: 'left' }}>Phone Number</div>
						<div className="text-left">{`${data.countryCode} ${data.phoneNumber}`}</div>
					</div>
				</div>
				<div className="col-6 pt-4">
					<div>
						<div style={{ fontWeight: 'bolder', textAlign: 'left' }}>Role</div>
						<div className="text-left">{`${role[0].roleName}`}</div>
					</div>
				</div>
				<div className="col-6 pt-4">
					<div>
						<div style={{ fontWeight: 'bolder', textAlign: 'left' }}>Currently active</div>
						<div className="text-left">{`${data.isActive ? 'Yes' : 'No'}`}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default viewModal;
