import React from 'react';
import FooterWrapper from './footer.style';
import moment from 'moment';

const Footer = (props) => {
	return (
		<FooterWrapper {...props}>
			<div className="footerBack">
				<ul className="list-inline ma-0">
					<li className="list-inline-item">© {moment().format('YYYY')} Novabooths, All rights reserved.</li>
				</ul>
			</div>
		</FooterWrapper>
	);
};

export default Footer;
