import React, { useRef, useState } from 'react';
import { ForgotIcon } from 'helper/constant';
import Toaster from '../../../components/common/Toaster';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import enhancer from './enhancer/ResetPasswordValidator';
import Button from '../../../components/button/Button';
import Api from '../../../helper/Api';
import { STATUS_CODES } from 'helper/constant';
const ChangePassword = (props) => {
	const [loading, setloading] = useState(false);

	const loginContainer = {
		backgroundPosition: 'center center',
		backgroundSize: 'cover',
		position: 'fixed',
		overflow: 'auto',
		top: 0,
		bottom: 0,
		backgroundColor: '#FEF5F5',
	};

	const { errors, touched, submitCount, isValid, handleSubmit, values, handleChange, handleBlur } = props;

	const toaster = useRef();
	// const [password, setPassword] = useState();

	const Error = (props) => {
		const field1 = props.field;
		if ((errors[field1] && touched[field1]) || submitCount > 0) {
			return <span className={props.class ? props.class : 'error-msg'}>{errors[field1]}</span>;
		} else {
			return <span></span>;
		}
	};

	function submitHandler(e) {
		e.preventDefault();
		if (isValid) {
			const params = {
				new_password: values.newPassword,
				email: props && props.email,
				otp: props && props.otp,
				//   otp_token:props && props.otp_token,
			};
			setloading(true);
			Api('POST', 'cms/admin/resetpassword', params)
				.then((res) => {
					if (res.status === STATUS_CODES.SUCCESS) {
						if (toaster.current) toaster.current.success(res.data.message);
						setTimeout(() => {
							setloading(false);
							props.history.push('./login');
						}, 500);
					} else {
						setloading(false);
						if (toaster.current) toaster.current.error(res.data.message);
					}
				})
				.catch((err) => {
					setloading(false);
					if (toaster.current) toaster.current.error(err.response);
				});
		}
		handleSubmit();
	}

	return (
		<div>
			<div className="container-fluid" style={loginContainer}>
				<div className="form-container">
					<div className="login-icon">
						<img src={ForgotIcon} alt="icon" height="100px" />
					</div>
					<div className="login-title">Reset Password</div>

					<div className="text-center form-info-text plr-24 mt-16">Enter Your new password</div>

					<form className="pa-24" onSubmit={(e) => submitHandler(e)}>
						<div className="form-group">
							<input
								id="newPassword"
								type="password"
								onPaste={(e) => e.preventDefault()}
								className="form-control react-form-input"
								aria-describedby="emailHelp"
								placeholder="Enter password"
								// value={values.newPassword}
								onBlur={handleBlur}
								onChange={handleChange}
							/>
							<Error field="newPassword" />
						</div>
						<div className="form-group">
							<input
								id="confirmPassword"
								type="password"
								onPaste={(e) => e.preventDefault()}
								// value={values.confirmPassword}
								className="form-control react-form-input"
								aria-describedby="emailHelp"
								placeholder="Confirm password"
								onChange={handleChange}
								onBlur={handleBlur}
							/>
							<Error field="confirmPassword" />
						</div>
						<Button
							type="submit"
							loading={loading}
							className="btn form-button"
							dataStyle="expand-right"
							style={{ position: 'relative', backgroundColor: '#f58322' }}
						>
							Save
						</Button>
					</form>
				</div>
			</div>
			<Toaster ref={toaster} />
		</div>
	);
};

export default compose(withRouter, enhancer)(ChangePassword);
