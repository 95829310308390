import React from 'react';
import './StaticContent.css';
const viewModal = (props) => {
	let data = props.staticContentData;
	return (
		<div className="wp-100">
			<div className="fs-15 demi-bold-text cursor-pointer float-right" onClick={() => props.toggle()}>
				<i className="fa fa-times"></i>
			</div>
			<div className="text-center fs-20 font-weight-bolder">Static Content information</div>

			<div className="row">
				<div className="col-12 pt-4">
					<div>
						<div style={{ fontWeight: 'bolder', textAlign: 'left' }}>Title</div>
						<div className="text-left media-title-value">{data.title}</div>
					</div>
				</div>
				{data.assetType === 'IMAGE' ? (
					<img
						src={data.assetName}
						className="mtb-15 media-style"
						onClick={() => window.open(data.assetName)}
						alt="static asset"
					></img>
				) : (
					<video src={data.assetName} alt="" className="mtb-15 media-style" controls />
				)}
			</div>
		</div>
	);
};

export default viewModal;
