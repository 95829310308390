import React from 'react';
import { compose } from 'redux';
import enhancer from './Validator';
import SmallLoader from '../common/SmallLoader';
import Loader from 'components/common/Loader';
import { Input } from 'reactstrap';
// import Api from 'helper/Api';
// import { ImageResolution } from '../../helper/constant';
// import Placeholder from '../../assets/images/Logo.png';
const AdminForm = (props) => {
	const { handleChange, handleBlur, errors, touched, submitCount, onSubmit, values, action } = props;

	const Error = (props) => {
		const field1 = props.field;
		if ((errors[field1] && touched[field1]) || submitCount > 0) {
			return <span className={props.class ? props.class : 'error-msg'}>{errors[field1]}</span>;
		} else {
			return <span />;
		}
	};

	const handleSubmit = () => {
		let { values, isValid, handleSubmit } = props;

		if (isValid) {
			onSubmit(values, action);
		}
		handleSubmit();
	};

	return (
		<div>
			{!props.loader ? (
				<form>
					<div className="form-group">
						<label className="fs-16 medium-text">First name</label>
						<input
							type="text"
							className="form-control react-form-input"
							id="firstName"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.firstName}
							placeholder=" First name"
						/>
						<Error field="firstName" />
					</div>

					<div className="form-group">
						<label className="fs-16 medium-text">Last name</label>
						<input
							type="text"
							className="form-control react-form-input"
							id="lastName"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.lastName}
							placeholder="Last name"
						/>
						<Error field="lastName" />
					</div>

					<div className="form-group">
						<label className="fs-16 medium-text">Email</label>
						<input
							type="text"
							className="form-control react-form-input"
							id="email"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.email}
							placeholder="Email"
							disabled={values.id ? true : false}
						/>
						<Error field="email" />
					</div>

					<div className="form-group">
						<label className="fs-16 medium-text">Role</label>

						{props.initialValues.isActive === true || action === 'add' ? (
							<Input
								type="select"
								name="role"
								id="role"
								value={values.role || 'select_role'}
								placeholder="Role"
								onChange={handleChange}
								onBlur={handleBlur}
							>
								<option value={'select_role'} disabled selected>
									Select role
								</option>
								<option value="1">Admin</option>
								<option value="2">SubAdmin</option>
							</Input>
						) : (
							<Input
								type="select"
								name="role"
								id="role"
								value={values.role || 'select_role'}
								placeholder="Role"
								onChange={handleChange}
								onBlur={handleBlur}
								disabled
							>
								<option value={'select_role'} disabled selected>
									Select role
								</option>
								<option value="1">Admin</option>
								<option value="2">SubAdmin</option>
							</Input>
						)}
						<Error field="role" />
					</div>

					<div>
						<div>
							{!props.loading && (
								<button
									type="button"
									className="c-btn c-info form-button fs-16 demi-bold-text"
									style={{ maxWidth: '125px' }}
									onClick={() => handleSubmit()}
									disabled={props.disabled}
								>
									Submit
								</button>
							)}
							{props.loading && <SmallLoader />}
						</div>
					</div>
				</form>
			) : (
				<Loader loading={props.loader} />
			)}
		</div>
	);
};

export default compose(enhancer)(AdminForm);
