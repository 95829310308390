import { withFormik } from 'formik';
import * as Yup from 'yup';

const formikEnhancer = withFormik({
	validationSchema: Yup.object().shape({
		firstName: Yup.string().required('This field is required'),
		lastName: Yup.string().required('This field is required'),
	}),
	mapPropsToValues: (props) => ({
		firstName: props.firstName,
		lastName: props.lastName,
		email: props.email,
		profilePic: props.profilePic,
	}),
	handleSubmit: (values) => {},
	displayName: 'CustomValidationForm',
	enableReinitialize: true,
});

export default formikEnhancer;
