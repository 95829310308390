import React, { useState, useEffect, useRef } from 'react';
import ReactTable from 'react-table';
import Button from 'components/button/Button';
import Pagination from 'components/common/Pagination';
import 'react-table/react-table.css';
import { withRouter } from 'react-router';
import Loader from '../common/Loader';
import moment from 'moment';
import Toaster from '../common/Toaster';
import { TABLE } from '../../helper/constant';
import Api from 'helper/Api';
import CommonModel from '../common/CommonModel';
import UserDetailsView from './UserViewModal';
import EventDetailsView from './EventViewModal';
import GalleryDetailsView from './GalleryViewModal';
import AuthActions from '../../redux/auth/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
import RoyTooltip from 'components/common/RoyTooltip';
const { admin } = AuthActions;

const EventTable = (props) => {
	const columns = [
		{
			Header: 'Event Name',
			accessor: 'eventName',
			className: 'text-center',
			filterable: false,
			headerClassName: 'react-table-header-class',
			Cell: (props) => <div>{`${props.original.eventName}`}</div>,
		},
		{
			Header: 'User Name',
			accessor: 'userName',
			className: 'text-center',
			filterable: false,
			headerClassName: 'react-table-header-class',
			Cell: (props) => <div>{`${props.original.user.firstName} ${props.original.user.lastName}`}</div>,
		},
		{
			Header: 'Event Date',
			accessor: 'eventDate',
			className: 'text-center',
			filterable: false,
			headerClassName: 'react-table-header-class',
			Cell: (props) => <div>{moment(props.original.eventDate).format('MMM-DD-YYYY')}</div>,
		},
		{
			Header: 'Status',
			accessor: 'status',
			className: 'text-center',
			headerClassName: 'react-table-header-class',
			sortable: false,
			filterable: false,
			width: 200,
			Cell: (props) => (
				<div className="react-action-class">
					{props.original.isActive ? (
						<div id={`active-${props.original.id}`}>
							<RoyTooltip id={`act-${props.original.id}`} title={'Click to Deactivate'} placement="left">
								<Button
									className="c-btn c-success mr-10"
									style={{ minWidth: '50px' }}
									onClick={() => activeInactiveStatusHandler(props.original.id)}
									id={`act-${props.original.id}`}
								>
									<div className="fs-14  ">
										<i class="fa fa-check" aria-hidden="true"></i>
									</div>
								</Button>
							</RoyTooltip>
						</div>
					) : (
						<div id={`deactive-${props.original.id}`}>
							<RoyTooltip id={`dact-${props.original.id}`} title={'Click to Activate'} placement="left">
								<Button
									style={{ minWidth: '50px' }}
									className="c-btn c-danger mr-10"
									onClick={() => activeInactiveStatusHandler(props.original.id)}
									id={`dact-${props.original.id}`}
								>
									<div>
										<div className="fs-14" id="hi">
											<i class="fa fa-times" aria-hidden="true"></i>
										</div>
									</div>
								</Button>
							</RoyTooltip>
						</div>
					)}
				</div>
			), // Custom cell components!
		},
		{
			Header: 'Action',
			accessor: 'action',
			className: 'text-center',
			headerClassName: 'react-table-header-class',
			sortable: false,
			filterable: false,
			width: 300,
			Cell: (props) => (
				<div className="react-action-class">
					<RoyTooltip id={`user-${props.original.id}`} title={'User Details'} placement="left">
						<Button
							className="c-btn mr-10"
							style={{ minWidth: '50px', backgroundColor: 'orange', color: 'white' }}
							onClick={() => {
								setUserModalOpen(true);
								setData(props.original);
							}}
							id={`user-${props.original.id}`}
						>
							<div className="fs-14 medium-text">
								<i className="fa fa-user-circle" />
							</div>
						</Button>
					</RoyTooltip>
					<RoyTooltip id={`event-${props.original.id}`} title={'Event Details'} placement="left">
						<Button
							className="c-btn mr-10"
							style={{ minWidth: '50px', color: 'white', backgroundColor: '#d47fff' }}
							onClick={() => {
								viewModalHandler(props.original.id, setEventModalOpen);
							}}
							id={`event-${props.original.id}`}
						>
							<div className="fs-14 medium-text">
								<i className="far fa-calendar-alt" />
							</div>
						</Button>
					</RoyTooltip>
					<RoyTooltip id={`gallery-${props.original.id}`} title={'Gallery Details'} placement="left">
						<Button
							className="c-btn  mr-10"
							style={{ minWidth: '50px', backgroundColor: '#3ac4c4', color: 'white' }}
							onClick={() => viewModalHandler(props.original.id, setGalleryModalOpen)}
							id={`gallery-${props.original.id}`}
						>
							<div className="fs-14 medium-text">
								<i className="fas fa-camera" />
							</div>
						</Button>
					</RoyTooltip>
				</div>
			), // Custom cell components!
		},
	];

	const [tblData, settblData] = useState();
	const [loader, setLoader] = useState(false);
	const [pages, setPages] = useState(0);
	const [activePage, setActivePage] = useState(0);
	const [searchEvent, setsearchEvent] = useState('');
	const [searchUser, setsearchUser] = useState('');
	const [field, setField] = useState('createdAt');
	const [sortBy, setSortBy] = useState('DESC');
	const [data, setData] = useState({});
	const [viewData, setViewData] = useState({});
	const [userModalOpen, setUserModalOpen] = useState(false);
	const [eventModalOpen, setEventModalOpen] = useState(false);
	const [galleryModalOpen, setGalleryModalOpen] = useState(false);

	const toaster = useRef();

	useEffect(() => {
		getList(field, sortBy, searchEvent, searchUser, activePage + 1);
		setPages(10);
	}, []);

	const activeInactiveStatusHandler = (id) => {
		setLoader(true);
		Api('GET', `cms/event/change/status/${id}`)
			.then((response) => {
				if (response.status === 200) {
					getList(field, sortBy, searchEvent, searchUser, activePage + 1);
					setLoader(false);
				}
			})
			.catch((error) => {
				if (toaster.current) toaster.current.error(error);
				setLoader(false);
			});
	};
	const viewModalHandler = (id, state) => {
		setLoader(true);
		Api('GET', `cms/event/fetch/details/${id}`)
			.then((response) => {
				if (response.status === 200) {
					setViewData(response.data.data);
					state(true);
					setLoader(false);
				}
			})
			.catch((error) => {
				if (toaster.current) toaster.current.error(error);
				setLoader(false);
			});
	};

	const onChangePageGetData = (state) => {
		if (state.page !== activePage) {
		}
	};

	function getList(field = 'createdAt', sortBy = 'DESC', search_event = '', search_user = '', page = '1') {
		setLoader(true);

		const params = {
			sort_by: field,
			order: sortBy,
			search_event: search_event.trim(),
			search_user: search_user.trim(),
			page: page,
		};

		// if (cancelTokenSource.current !== null) {
		// 	cancelTokenSource.current.cancel('Cancel Previous Request');
		// }

		// cancelTokenSource.current = Axios.CancelToken.source();

		// params.apiCancelToken = cancelTokenSource.current.token;

		Api('POST', 'cms/event/list', params)
			.then((res) => {
				if (res.status === 200) {
					settblData(res.data.data.rows);
					setPages(Math.ceil(res.data.data.count / TABLE.LIMIT));
					setLoader(false);
				} else {
					setLoader(false);
					if (toaster.current) toaster.current.error(res.data.message);
				}
			})
			.catch((err) => {
				setLoader(false);
				if (toaster.current) toaster.current.error(err.response);
			});
	}

	return (
		<div>
			<div className="plr-15">
				<div className="roe-card-style mtb-15">
					<div className="roe-card-header module-header">
						<div className="flex-1 fs-16 demi-bold-text" />
						<div className="mr-10">
							<input
								value={searchEvent}
								onChange={(e) => {
									setsearchEvent(e.target.value);
									const search_event = e.target.value;
									setLoader(true);
									setActivePage(0);
									getList(field, sortBy, search_event, searchUser, 1);
								}}
								type="text"
								placeholder="Search by Event Name"
								className="fs-14 medium-text plr-10 form-control react-form-input"
							/>
						</div>
						<div className="mr-10">
							<input
								value={searchUser}
								onChange={(e) => {
									setsearchUser(e.target.value);
									const search_user = e.target.value;
									setLoader(true);
									setActivePage(0);
									getList(field, sortBy, searchEvent, search_user, 1);
								}}
								type="text"
								placeholder="Search by User Name"
								className="fs-14 medium-text plr-10 form-control react-form-input"
							/>
						</div>
					</div>
					<div className="roe-card-body">
						<ReactTable
							style={{
								border: 'none',
								boxShadow: 'none',
							}}
							data={tblData}
							columns={columns}
							defaultPageSize={10}
							filterable
							minRows={2}
							manual
							defaultFilterMethod={(filter, row) => {
								const id = filter.pivotId || filter.id;

								return row[id] !== undefined
									? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
									: true;
							}}
							className="-striped -highlight custom-react-table-theme-class"
							pages={pages}
							page={activePage}
							PaginationComponent={Pagination}
							loading={loader}
							LoadingComponent={Loader}
							onFetchData={onChangePageGetData}
							onPageChange={(pageIndex) => {
								setActivePage(pageIndex);
								getList(field, sortBy, searchEvent, searchUser, pageIndex + 1);
							}}
							onSortedChange={(sortProperties) => {
								const sort = sortProperties[0].desc ? 'DESC' : 'ASC';
								setSortBy(sort);
								setActivePage(0);
								setField(sortProperties[0].id);
								getList(sortProperties[0].id, sort, searchEvent, searchUser, 1);
							}}
						/>
					</div>
					{userModalOpen && (
						<CommonModel
							modal={userModalOpen}
							size="lg"
							title="User information"
							style={{ maxWidth: '800px', width: '80%' }}
							children={<UserDetailsView data={data} toggle={() => setUserModalOpen(false)} />}
						/>
					)}
					{eventModalOpen && (
						<CommonModel
							modal={eventModalOpen}
							size="lg"
							title="Event information"
							style={{ maxWidth: '800px', width: '80%' }}
							children={<EventDetailsView data={viewData} toggle={() => setEventModalOpen(false)} />}
						/>
					)}
					{galleryModalOpen && (
						<CommonModel
							modal={galleryModalOpen}
							size="lg"
							title="Media information"
							style={{ maxWidth: '800px', width: '80%' }}
							children={<GalleryDetailsView data={viewData} toggle={() => setGalleryModalOpen(false)} />}
						/>
					)}
				</div>
			</div>
			<Toaster ref={toaster} />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		role: state.auth.role,
		id: state.auth.id,
		...state.themeChanger,
	};
};
export default compose(withRouter, connect(mapStateToProps, { admin }))(EventTable);
