import React, { useRef, useState, useEffect } from 'react';
import { iconDemo } from 'helper/constant';
import { STATUS_CODES } from 'helper/constant';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import AuthActions from 'redux/auth/actions';
import enhancer from './enhancer/LoginFormEnhancer';
import Toaster from '../../../components/common/Toaster';
import Button from '../../../components/button/Button';
import Api from '../../../helper/Api';

const { login } = AuthActions;

const Login = (props) => {
	const [loading, setloading] = useState(false);

	const toaster = useRef();

	useEffect(() => {
		if (props.isLogin) {
			props.history.push('/dashboard');
		}
	}, []);

	const handleLogin = (e) => {
		e.preventDefault();
		let { values, handleSubmit } = props;

		if (values.email !== '' && values.password !== '') {
			const params = {
				email: values.email,
				password: values.password,
			};
			setloading(true);
			Api('POST', 'cms/admin/login', params)
				.then((res) => {
					if (res.status === STATUS_CODES.SUCCESS) {
						props.login({
							token: res.data.data.token,
							firstName: res.data.data.admin.firstName,
							lastName: res.data.data.admin.lastName,
							email: res.data.data.admin.email,
							id: res.data.data.admin.id,
							profilePic: res.data.data.admin.profilePic,
							role: res.data.data.admin.role,
						});
						setloading(false);
						setTimeout(() => {
							props.history.push('/dashboard');
						}, 500);
					}
					// else {
					//   setloading(false);
					//   if (toaster.current) toaster.current.error(res.data.message);
					// }
				})
				.catch((err) => {
					setloading(false);
					if (toaster.current) toaster.current.error(err.response);
				});
			//   const data = {
			//     token: "DEMOJWTTOKEN"
			//   };
			// using this method you can store token in redux

			//   props.history.push("/dashboard");
		}
		handleSubmit();
	};

	const { values, handleChange, handleBlur, errors, touched, submitCount } = props;

	const loginContainer = {
		backgroundPosition: 'center center',
		backgroundSize: 'cover',
		position: 'fixed',
		overflow: 'auto',
		top: 0,
		bottom: 0,
		backgroundColor: '#FEF5F5',
	};

	const Error = (props) => {
		const field1 = props.field;
		if ((errors[field1] && touched[field1]) || submitCount > 0) {
			return <span className={props.class ? props.class : 'error-msg'}>{errors[field1]}</span>;
		} else {
			return <span />;
		}
	};

	return (
		<div className="container-fluid" style={loginContainer}>
			<div className="form-container">
				<div className="login-icon">
					<img src={iconDemo} alt="icon" height="100px" />
				</div>
				<div className="login-title">Sign in to your account</div>
				<form className="pa-24" onSubmit={handleLogin}>
					<div className="form-group">
						<label>Email</label>
						<input
							type="email"
							className="form-control react-form-input"
							id="email"
							onChange={handleChange}
							value={values.email}
							onBlur={handleBlur}
							placeholder="Email"
						/>
						<Error field="email" />
					</div>

					<div className="form-group">
						<label>Password</label>
						<input
							type="password"
							className="form-control react-form-input"
							id="password"
							value={values.password}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder="Password"
						/>
						<Error field="password" />
					</div>

					<Button
						type="submit"
						loading={loading}
						className="btn form-button"
						dataStyle="expand-right"
						style={{ position: 'relative', backgroundColor: '#f58322' }}
					>
						Login
					</Button>
					<div className="text-center link-label" onClick={() => props.history.push('/forgot-password')}>
						Forgot Password ?
					</div>
				</form>
			</div>
			<Toaster ref={toaster} />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		isLogin: state.auth.isLogin,
	};
};

export default compose(withRouter, enhancer, connect(mapStateToProps, { login }))(Login);
