import React, { Component } from 'react';
import CustomToast from '../../components/notification/CustomToast';
import { store } from 'redux/store';

class Toaster extends Component {
	state = {
		editedData: null,
		toast: false,
		toastify: {},
	};

	error = (error) => {
		if (error && error.data) {
			this.setState({
				toast: true,
				toastify: {
					message: error.data.message,
					styleClass: 'c-danger',
				},
			});
		} else {
			this.setState({
				toast: true,
				toastify: {
					message: error,
					styleClass: 'c-danger',
				},
			});
		}

		if (error && error.status === 401) {
			store.dispatch({ type: 'LOGOUT' });
		}
		setTimeout(() => {
			this.setState({ toast: false });
		}, 2000);
	};

	success = (message) => {
		this.setState({
			toast: true,
			toastify: {
				message: message,
				styleClass: 'c-info',
			},
		});
		setTimeout(() => {
			this.setState({ toast: false });
		}, 2000);
	};

	info = (message) => {
		this.setState({
			toast: true,
			toastify: {
				message: message,
				styleClass: 'c-light',
			},
		});
		setTimeout(() => {
			this.setState({ toast: false });
		}, 2000);
	};

	render() {
		const { toastify, toast } = { ...this.state };
		return (
			<CustomToast
				width={400}
				show={toast}
				transition={true}
				position="top-right"
				className={toastify && toastify.styleClass}
				message={toastify && toastify.message}
				onCloseCLick={() => this.setState({ toast: false })}
			/>
		);
	}
}

export default Toaster;
