import React from 'react';
import EventWrapper from '../event/Event.Style';
import moment from 'moment';

const EventDetailsView = (props) => {
	let data = props.data;

	return (
		<>
			<EventWrapper>
				<div className="wp-100">
					<div className="fs-15 demi-bold-text cursor-pointer float-right" onClick={() => props.toggle()}>
						<i className="fa fa-times"></i>
					</div>
					<div className=" fs-20 font-weight-bolder">Event information</div>
					<hr />
					<div className="row">
						<div className="col-6 pt-4">
							<div>
								<div className="text-heading">Event Name</div>
								<div className="text-left">{`${data.eventName}`}</div>
							</div>
						</div>
						<div className="col-6 pt-4">
							<div>
								<div className="text-heading">Event Date</div>
								<div className="text-left">{moment(data.eventDate).format('MMM-DD-YYYY')}</div>
							</div>
						</div>
						<div className="col-6 pt-4">
							<div>
								<div className="text-heading">Show Overlay</div>
								<div className="text-left">{`${data.isShowOverlay ? 'Yes' : 'No'}`}</div>
							</div>
						</div>
						<div className="col-6 pt-4">
							<div>
								<div className="text-heading">Camera Mirror</div>
								<div className="text-left">{`${data.isCameraMirror ? 'Yes' : 'No'}`}</div>
							</div>
						</div>
						<div className="col-6 pt-4">
							<div>
								<div className="text-heading">Countdown</div>
								<div className="text-left">{`${data.countDown ? 'Yes' : 'No'}`}</div>
							</div>
						</div>
						<div className="col-6 pt-4">
							<div>
								<div className="text-heading">Survey</div>
								<div className="text-left">{`${data.isSurvey ? 'Yes' : 'No'}`}</div>
							</div>
						</div>

						<div className="col-6 pt-4">
							<div>
								<div className="text-heading">Currently active</div>
								<div className="text-left">{`${data.isActive ? 'Yes' : 'No'}`}</div>
							</div>
						</div>
						<div className="col-6 pt-4">
							<div>
								<div className="text-heading">GIF</div>
								<div className="text-left">{`${data.capture_setting.isGIF ? 'Yes' : 'No'}`}</div>
							</div>
						</div>
						{data.capture_setting.isGIF ? (
							<>
								<div className="col-6 pt-4">
									<div>
										<div className="text-heading">GIF Frame Number</div>
										<div className="text-left">{data.capture_setting.gifFrameNumber}</div>
									</div>
								</div>
								<div className="col-6 pt-4">
									<div>
										<div className="text-heading">GIF Timer</div>
										<div className="text-left">{data.capture_setting.gifTimer}</div>
									</div>
								</div>
							</>
						) : (
							''
						)}
						<div className="col-6 pt-4">
							<div>
								<div className="text-heading">Boomerang</div>
								<div className="text-left">{`${data.capture_setting.isBoomerang ? 'Yes' : 'No'}`}</div>
							</div>
						</div>
						{data.capture_setting.isBoomerang ? (
							<div className="col-6 pt-4">
								<div>
									<div className="text-heading">Boomerang Frame Seconds</div>
									<div className="text-left">{`${data.capture_setting.boomerangFrameSeconds}`}</div>
								</div>
							</div>
						) : (
							''
						)}
						<div className="col-6 pt-4">
							<div>
								<div className="text-heading">Video</div>
								<div className="text-left">{`${data.capture_setting.isVideo ? 'Yes' : 'No'}`}</div>
							</div>
						</div>
						{data.capture_setting.isVideo ? (
							<>
								<div className="col-6 pt-4">
									<div>
										<div className="text-heading">Video Length</div>
										<div className="text-left">{data.capture_setting.videoLength}</div>
									</div>
								</div>
								<div className="col-6 pt-4">
									<div>
										<div className="text-heading">Record Audio</div>
										<div className="text-left">{`${data.capture_setting.isRecordAudio ? 'Yes' : 'No'}`}</div>
									</div>
								</div>
							</>
						) : (
							''
						)}
						{data.isDisplayDisclaimer ? (
							<div>
								<div>
									<div className="disclaimer-heading">Disclaimer</div>
									<div className="disclaimer-content">{`${data.disclaimerContent}`}</div>
								</div>
							</div>
						) : (
							<div className="col-6 pt-4">
								<div className="text-heading">Disclaimer</div>
								<div className="text-left">No</div>
							</div>
						)}
					</div>
				</div>
				<br />
				<div className="wp-100 topic">
					<div className=" fs-20 font-weight-bolder">Tap To Start Information</div>
					<hr />
					<div className="row">
						<div>
							{!data.capture_setting.tapToStartImage ? (
								<div className="text-not-configured">No specific Tap to Start Image Configured</div>
							) : (
								<div className="text-left filter-list">
									<img
										src={data.capture_setting.tapToStartImage}
										alt=""
										className="mtb-15 overlay-style"
										onClick={() => window.open(data.capture_setting.tapToStartImage)}
									/>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="wp-100 topic">
					<div className=" fs-20 font-weight-bolder">Filter Information</div>
					<hr />
					<div className="row">
						<div>
							{!data.event_filters[0] ? (
								<div className="text-not-configured">No Filter Selected</div>
							) : (
								data.event_filters.map((element) => {
									return (
										<div className="text-left filter-list">
											<li>{element.filter.filterName}</li>
										</div>
									);
								})
							)}
						</div>
					</div>
				</div>
				<br />
				<div className="wp-100 topic">
					<div className=" fs-20 font-weight-bolder">Survey Information</div>
					<hr />
					<div className="row">
						{!data.survey_questions[0] ? (
							<div className="text-not-configured">No Survey Configured</div>
						) : (
							<ol className="text-left">
								{data.survey_questions.map((element) => {
									return (
										<li className="text-heading">
											{element.question}
											<ol type="A">
												{element.survey_multiple_choice_options.map((options) => {
													return <li className="options">{options.options}</li>;
												})}
											</ol>
										</li>
									);
								})}
							</ol>
						)}
					</div>
				</div>
			</EventWrapper>
		</>
	);
};

export default EventDetailsView;
