import styled from 'styled-components';

const NotificationWrapper = styled.div`
	.text-heading {
		font-weight: bolder;
		text-align: left;
	}
	.notification-heading {
		font-weight: bolder;
		text-align: center;
		margin: 20px;
	}
	.notification-title {
		text-align: center;
		margin: 20px;
	}
	.notification-content {
		text-align: center;
		margin: 20px;
	}
`;
export default NotificationWrapper;
