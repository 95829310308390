import React from 'react';
import PageWrapper from './user.style';
import PageTitle from 'components/common/PageTitle';
import { connect } from 'react-redux';
import ShareContentTable from '../../components/sharecontent/ShareContentTable';

const ShareContent = (props) => {
	return (
		<PageWrapper {...props}>
			<PageTitle title="sidebar.sharecontent" className="plr-15" />
			<div className="user-tbl">
				<ShareContentTable {...props} />
			</div>
		</PageWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.themeChanger,
	};
};

export default connect(mapStateToProps, null)(ShareContent);
