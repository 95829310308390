const authActions = {
	LOGIN: 'LOGIN',
	LOGOUT: 'LOGOUT',
	ADMIN: 'ADMIN',

	login: (data) => {
		localStorage.setItem('firstName', data.firstName);
		localStorage.setItem('lastName', data.lastName);
		return {
			type: authActions.LOGIN,
			isLogin: true,
			accessToken: data.token,
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			id: data.id,
			profilePic: data.profilePic,
			modules: data.modules,
			userType: data.userType,
			role: data.role,
		};
	},

	logout: () => {
		return {
			type: authActions.LOGOUT,
			isLogin: false,
			accessToken: null,
		};
	},

	admin: (data) => {
		return {
			type: authActions.ADMIN,
			firstName: data.firstName,
			lastName: data.lastName,
			email: data.email,
			profilePic: data.profilePic,
			role: data.role,
		};
	},
};

export default authActions;
