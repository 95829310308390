import React from 'react';
import Placeholder from '../../assets/images/Logo.png';

const viewModal = (props) => {
	let data = props.data;
	return (
		<div className="wp-100">
			<div className="fs-15 demi-bold-text cursor-pointer float-right" onClick={() => props.toggle()}>
				<i className="fa fa-times"></i>
			</div>
			<div className=" fs-20 font-weight-bolder">Filter information</div>
			<hr />
			<div className="row">
				<div className="col-12 pt-4">
					<div>
						<div style={{ fontWeight: 'bolder', textAlign: 'left' }}>Filter File</div>
						<img
							src={data.fileName ? data.fileName : Placeholder}
							alt=""
							height="180px"
							width="300px"
							style={{ borderRadius: '6%' }}
						/>
					</div>
				</div>
				<div className="col-6 pt-4">
					<div>
						<div style={{ fontWeight: 'bolder', textAlign: 'left' }}>Filter Name</div>
						<div className="text-left">{`${data.filterName}`}</div>
					</div>
				</div>
				<div className="col-6 pt-4">
					<div>
						<div style={{ fontWeight: 'bolder', textAlign: 'left' }}>Color</div>
						{/* <div className="text-left">{`${data.colorCpo ? data.phoneNumber : '--'}`}</div> */}
						<div
							style={{
								backgroundColor: data.colorCode,
								width: '28px',
								height: '28px',
								borderRadius: '50%',
							}}
						></div>
					</div>
				</div>
				<div className="col-6 pt-4">
					<div>
						<div style={{ fontWeight: 'bolder', textAlign: 'left' }}>Color Code</div>
						<div className="text-left">{`${data.colorCode}`}</div>
					</div>
				</div>
				<div className="col-6 pt-4">
					<div>
						<div style={{ fontWeight: 'bolder', textAlign: 'left' }}>Currently active</div>
						<div className="text-left">{`${data.isActive ? 'Yes' : 'No'}`}</div>
					</div>
				</div>
				<div className="col-6 pt-4">
					<div>
						<div style={{ fontWeight: 'bolder', textAlign: 'left' }}>LUT Size</div>
						<div className="text-left">{`${data.lutSize}`}</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default viewModal;
