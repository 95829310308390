import React from 'react';
import { compose } from 'redux';
import enhancer from './Validator';
import SmallLoader from '../common/SmallLoader';
import Loader from 'components/common/Loader';

const NotificationForm = (props) => {
	const { handleChange, handleBlur, errors, touched, submitCount, onSubmit, values, action } = props;

	const Error = (props) => {
		const field1 = props.field;
		if ((errors[field1] && touched[field1]) || submitCount > 0) {
			return <span className={props.class ? props.class : 'error-msg'}>{errors[field1]}</span>;
		} else {
			return <span />;
		}
	};

	const handleSubmit = () => {
		let { values, isValid, handleSubmit } = props;

		if (isValid) {
			onSubmit(values, action);
		}
		handleSubmit();
	};

	return (
		<div>
			{!props.loader ? (
				<form>
					<div className="form-group">
						<label className="fs-16 medium-text">Notification Title</label>
						<input
							type="text"
							className="form-control react-form-input"
							id="title"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.filterName}
							placeholder="Notification Title"
						/>
						<Error field="title" />
					</div>
					<div>
						<div className="form-group">
							<label className="fs-16 medium-text">Notification Content</label>
							<textarea
								className="form-control react-form-input"
								id="content"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.content}
								placeholder="Notification Content"
								maxlength="250"
							/>
							<label className="fs-12">(Max 250 Characters)</label>
							<br />
							<Error field="content" />
							<br />
						</div>
					</div>
					<div>
						<div>
							{!props.loading && (
								<button
									type="button"
									className="c-btn c-info form-button fs-16 demi-bold-text"
									style={{ maxWidth: '125px' }}
									onClick={() => handleSubmit()}
									disabled={props.disabled}
								>
									Send
								</button>
							)}
							{props.loading && <SmallLoader />}
						</div>
					</div>
				</form>
			) : (
				<Loader loading={props.loader} />
			)}
		</div>
	);
};

export default compose(enhancer)(NotificationForm);
