import React, { useState, useEffect, useRef } from 'react';
import ReactTable from 'react-table';
import Button from 'components/button/Button';
import Pagination from 'components/common/Pagination';
import 'react-table/react-table.css';
import { withRouter } from 'react-router';
import Loader from '../common/Loader';
import moment from 'moment';
import Toaster from '../common/Toaster';
import { STATUS_CODES, TABLE } from '../../helper/constant';
import Api from 'helper/Api';
import CommonModel from '../common/CommonModel';
import View from './ViewModal';
import RoyTooltip from 'components/common/RoyTooltip';
import AuthActions from '../../redux/auth/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';
const { admin } = AuthActions;

const StaticPageTable = (props) => {
	const [tblData, settblData] = useState();
	const [loader, setLoader] = useState(false);
	const [pages, setPages] = useState(0);
	const [activePage, setActivePage] = useState(0);
	const [field, setField] = useState('updatedAt');
	const [sortBy, setSortBy] = useState('DESC');
	const [modal, setModal] = useState(false);
	const [staticPageData, setStaticPageData] = useState({});
	const [view, setView] = useState(false);

	const toaster = useRef();

	useEffect(() => {
		getList(field, sortBy, activePage + 1);
		setPages(1);
	}, []);
	const { role } = props;
	const columns = [
		{
			Header: 'Title',
			accessor: 'title',
			className: 'text-center',
			filterable: false,
			headerClassName: 'react-table-header-class',
		},
		{
			Header: 'Date',
			accessor: 'updatedAt',
			className: 'text-center',
			filterable: false,
			headerClassName: 'react-table-header-class',
			Cell: (props) => <div>{moment(props.original.updatedAt).format('MMM-DD-YYYY')}</div>,
		},
		{
			Header: 'Action',
			accessor: 'action',
			className: 'text-center',
			headerClassName: 'react-table-header-class',
			sortable: false,
			filterable: false,
			width: 280,
			Cell: (props) => (
				<div className="react-action-class">
					<div>
						<RoyTooltip id={`view-${props.original.id}`} title={'View'} placement="left">
							<Button
								className="c-btn c-primary mr-10"
								style={{ minWidth: '50px' }}
								onClick={() => {
									setView(true);
									setModal(true);
									setStaticPageData(props.original);
								}}
								id={`view-${props.original.id}`}
							>
								<div className="fs-14 medium-text">
									<i className="fas fa-info  " />
								</div>
							</Button>
						</RoyTooltip>
						{role === 1 ? (
							<RoyTooltip id={`edit-${props.original.id}`} title={'Edit'} placement="right">
								<Button
									className="c-btn c-success mr-10"
									style={{ minWidth: '50px' }}
									onClick={() => formAction('edit', props.original)}
									id={`edit-${props.original.id}`}
								>
									<div className="fs-14 medium-text">
										<i className="fas fa-edit" />
									</div>
								</Button>
							</RoyTooltip>
						) : (
							''
						)}
					</div>
				</div>
			), // Custom cell components!
		},
	];

	const onChangePageGetData = (state) => {
		if (state.page !== activePage) {
		}
	};

	const formAction = (action, data = null) => {
		// Here you can view the data and make forward action for edit data
		if (action === 'add') {
			props.history.push('/staticpage/add');
		} else if (action === 'edit') {
			props.history.push(`/static-page/edit/${data.id}`);
		}
	};

	function getList(field = 'updatedAt', sortBy = 'DESC', search = '', page = '1') {
		const params = {
			sort_by: field,
			order: sortBy,
			search: search,
			page: page,
		};
		setLoader(true);

		Api('POST', 'cms/static/list', params)
			.then((res) => {
				if (res.status === STATUS_CODES.SUCCESS) {
					settblData(res.data.data.rows);
					setPages(Math.ceil(res.data.data.count / TABLE.LIMIT));
					setLoader(false);
				} else {
					if (toaster.current) toaster.current.error(res.data.message);
				}
			})
			.catch((err) => {
				if (toaster.current) toaster.current.error(err.response);
			});
	}

	return (
		<div>
			<div className="plr-15">
				<div className="roe-card-style mtb-15">
					<div className="roe-card-header module-header">
						<div className="flex-1 fs-16 demi-bold-text" />
					</div>
					<div className="roe-card-body">
						<ReactTable
							style={{
								border: 'none',
								boxShadow: 'none',
							}}
							data={tblData}
							columns={columns}
							defaultPageSize={10}
							filterable
							minRows={2}
							manual
							defaultFilterMethod={(filter, row) => {
								const id = filter.pivotId || filter.id;

								return row[id] !== undefined
									? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
									: true;
							}}
							className="-striped -highlight custom-react-table-theme-class"
							pages={pages}
							page={activePage}
							PaginationComponent={Pagination}
							loading={loader}
							LoadingComponent={Loader}
							onFetchData={onChangePageGetData}
							onPageChange={(pageIndex) => {
								setActivePage(pageIndex);
								getList(field, sortBy, pageIndex + 1);
							}}
							onSortedChange={(sortProperties) => {
								const sort = sortProperties[0].desc ? 'DESC' : 'ASC';
								setSortBy(sort);
								setActivePage(0);
								setField(sortProperties[0].id);
								getList(sortProperties[0].id, sort, 1);
							}}
						/>
					</div>

					{view && (
						<CommonModel
							modal={modal}
							size="xl"
							style={{ maxWidth: '1600px', width: '80%' }}
							children={
								<View
									staticPageData={staticPageData}
									toggle={() => {
										setModal(false);
									}}
								/>
							}
						/>
					)}
				</div>
			</div>
			<Toaster ref={toaster} />
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		role: state.auth.role,
		id: state.auth.id,
		...state.themeChanger,
	};
};
export default compose(withRouter, connect(mapStateToProps, { admin }))(StaticPageTable);
// export default withRouter(StaticPageTable);
