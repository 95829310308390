import React from 'react';
import EventWrapper from '../event/Event.Style';

const userViewModal = (props) => {
	let data = props.data;
	return (
		<>
			<EventWrapper>
				<div className="wp-100">
					<div className="fs-15 demi-bold-text cursor-pointer float-right" onClick={() => props.toggle()}>
						<i className="fa fa-times"></i>
					</div>
					<div className=" fs-20 font-weight-bolder check">User information</div>
					<hr />
					<div className="row">
						<div className="col-6 pt-4">
							<div>
								<div className="text-heading">First Name</div>
								<div className="text-left">{`${data.user.firstName}`}</div>
							</div>
						</div>
						<div className="col-6 pt-4">
							<div>
								<div className="text-heading">Last Name</div>
								<div className="text-left">{`${data.user.lastName}`}</div>
							</div>
						</div>
						<div className="col-6 pt-4">
							<div>
								<div className="text-heading">Email</div>
								<div className="text-left">{`${data.user.email}`}</div>
							</div>
						</div>
						<div className="col-6 pt-4">
							<div>
								<div className="text-heading">Phone Number</div>
								<div className="text-left">{`${data.user.countryCode} ${data.user.phoneNumber}`}</div>
							</div>
						</div>
						<div className="col-6 pt-4">
							<div>
								<div className="text-heading">Address</div>
								<div className="text-left">{`${data.user.address}`}</div>
							</div>
						</div>
						<div className="col-6 pt-4">
							<div>
								<div className="text-heading">Currently active</div>
								<div className="text-left">{`${data.user.isActive ? 'Yes' : 'No'}`}</div>
							</div>
						</div>
					</div>
				</div>
			</EventWrapper>
		</>
	);
};

export default userViewModal;
