import { withFormik } from 'formik';
import * as Yup from 'yup';

const formikEnhancer = withFormik({
	validationSchema: Yup.object().shape({
		firstName: Yup.string()
			.trim()
			.required('This field is required'),
		lastName: Yup.string()
			.trim()
			.required('This field is required'),
		email: Yup.string()
			.email()
			.trim()
			.required('This field is required'),
		// profilePhoto: Yup.string()
		//   .nullable()
		//   .required("This field is required"),
		role: Yup.string().required('This field is required'),
	}),
	mapPropsToValues: (props) => ({
		id: props && props.editedData && props.editedData.id ? props.editedData.id : '',
		firstName: props && props.editedData && props.editedData.firstName ? props.editedData.firstName : '',
		lastName: props && props.editedData && props.editedData.lastName ? props.editedData.lastName : '',
		email: props && props.editedData && props.editedData.email ? props.editedData.email : '',
		// profilePhoto:
		//   props && props.editedData && props.editedData.profilePic
		//     ? props.editedData.profilePic
		//     : "",
		role: props && props.editedData && props.editedData.role ? props.editedData.role : '',
		isActive: props && props.editedData && props.editedData.isActive ? props.editedData.isActive : '',
		// // props.editedData.admin_role.id
		//   ? props.editedData.admin_role.id
		//   : "",
	}),
	handleSubmit: (values) => {},
	displayName: 'CustomValidationForm',
	enableReinitialize: true,
});

export default formikEnhancer;
