import React, { useState, useRef } from 'react';
import EventWrapper from '../event/Event.Style';
import Placeholder from '../../assets/images/Logo.png';
import Api from 'helper/Api';
import Toaster from '../common/Toaster';
import Loader from '../common/Loader';

const GalleryDetailsView = (props) => {
	let data = props.data;
	const toaster = useRef();
	const [loader, setLoader] = useState(false);

	const download = () => {
		setLoader(true);
		let params = {
			eventId: data.id,
		};
		Api('POST', `cms/event/media/download`, params)
			.then((response) => {
				if (response.status === 200) {
					setLoader(false);
					let link = document.createElement('a');
					link.href = response.data.data.linkToDownloadZip;
					// link.download = `${data.eventName}.zip`;
					link.click();
				}
			})
			.catch((error) => {
				if (toaster.current) toaster.current.error(error);
				setLoader(false);
			});
	};

	return loader ? (
		<Loader loading={loader} />
	) : (
		<>
			<EventWrapper>
				<div className="wp-100">
					<div className="fs-15 demi-bold-text cursor-pointer float-right" onClick={() => props.toggle()}>
						<i className="fa fa-times"></i>
					</div>
					<div className=" fs-20 font-weight-bolder check">Gallery information</div>
					<hr />

					<div className="wp-100">
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
							<div className="text-heading">Event Media</div>
							<button className="c-btn mr-10" style={{ backgroundColor: '#FF6767', color: 'white' }} onClick={download}>
								Download All Media
							</button>
						</div>

						<div className="row">
							{!data.event_media[0] ? (
								<div className="text-not-configured">No Media</div>
							) : (
								data.event_media.map((element) => {
									return element.mediaType === 'IMAGE' ? (
										<img
											src={element.mediaOriginal ? element.mediaOriginal : Placeholder}
											alt=""
											className="mtb-15 media-style"
											onClick={() => window.open(element.mediaOriginal)}
										/>
									) : (
										<video
											src={element.mediaOriginal ? element.mediaOriginal : Placeholder}
											alt=""
											className="mtb-15 media-style"
											controls
											loop
										/>
									);
								})
							)}
						</div>
					</div>

					<div className="wp-100">
						<div className="text-heading topic">Event Overlay</div>
						<div className="row">
							{!data.event_overlays[0] ? (
								<div className="text-not-configured">No Overlays</div>
							) : (
								data.event_overlays.map((element) => {
									return (
										<img
											src={element.overlayFile ? element.overlayFile : Placeholder}
											alt=""
											className="mtb-15 overlay-style"
											onClick={() => window.open(element.overlayFile)}
										/>
									);
								})
							)}
						</div>
					</div>
				</div>
				<Toaster ref={toaster} />
			</EventWrapper>
		</>
	);
};

export default GalleryDetailsView;
