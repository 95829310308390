import { themeConfig } from './index';

const changeThemes = {
	id: 'changeThemes',
	label: 'themeChanger',
	defaultTheme: themeConfig.theme,
	options: [
		{
			themeName: 'themedefault',
			buttonColor: '#ffffff',
			textColor: '#323332',
		},
	],
};

const topbarTheme = {
	id: 'topbarTheme',
	label: 'themeChanger.topbar',
	defaultTheme: themeConfig.topbar,
	options: [
		{
			themeName: 'themedefault',
			buttonColor: '#ffffff',
			textColor: '#323332',
			backgroundColor: '#ffffff',
		},
		{
			themeName: 'theme1',
			buttonColor: '#273135',
			backgroundColor: '#273135',
			textColor: '#ffffff',
		},
		{
			themeName: 'theme2',
			buttonColor: '#241d3b',
			backgroundColor: '#241d3b',
			textColor: '#ffffff',
		},
		{
			themeName: 'theme3',
			buttonColor: '#61696C',
			backgroundColor: '#61696C',
			textColor: '#ffffff',
		},
		{
			themeName: 'theme4',
			buttonColor: '#EBECEC',
			backgroundColor: '#EBECEC',
			textColor: '#000000',
		},
		{
			themeName: 'theme5',
			buttonColor: 'rgba(68, 70, 79, 0.5)',
			backgroundColor: 'rgba(68, 70, 79, 0.5)',
			textColor: '#ffffff',
		},
		{
			themeName: 'theme6',
			buttonColor: '#794c8a',
			backgroundColor: '#794c8a',
			textColor: '#ffffff',
		},
		{
			themeName: 'theme7',
			buttonColor: '#422e62',
			backgroundColor: '#422e62',
			textColor: '#ffffff',
		},
	],
};

const sidebarTheme = {
	id: 'sidebarTheme',
	label: 'themeChanger.sidebar',
	defaultTheme: themeConfig.sidebar,
	options: [
		{
			themeName: 'themedefault',
			button241d3bColor: '#241d3b',
			backgroundColor: '#fad8d8',
			textColor: '#1C1C1C',
			textColorNew: '#ffffff',
			activeColor: '#ff6767',
			activeTextColor: '#B78BF5',
		},
		{
			themeName: 'theme1',
			buttonColor: '#273135',
			backgroundColor: '#273135',
			textColor: '#ffffff',
			activeColor: '#FA7252',
			activeTextColor: '#FA7252',
		},
		{
			themeName: 'theme2',
			buttonColor: '#eeeeee',
			backgroundColor: '#eeeeee',
			textColor: '#000000',
			activeColor: '#A2A2A2',
			header: '#A2A2A2',
			activeTextColor: '#6200ea',
		},
		{
			themeName: 'theme3',
			buttonColor: '#2a2d35',
			backgroundColor: '#2a2d35',
			textColor: '#ffffff',
			activeColor: '#f6a821',
			activeTextColor: '#f6a821',
		},
		{
			themeName: 'theme4',
			buttonColor: '#422e62',
			backgroundColor: '#422e62',
			textColor: '#ffffff',
			activeColor: '#75678C',
			activeTextColor: '#D2E690',
		},
		{
			themeName: 'theme5',
			buttonColor: '#6c757d',
			backgroundColor: '#6c757d ',
			textColor: '#ffffff',
			activeColor: '#4F565B',
			activeTextColor: '#282B2E',
		},
		{
			themeName: 'theme6',
			buttonColor: '#DDD2E1',
			backgroundColor: '#DDD2E1',
			textColor: '#000',
			activeColor: '#9D7CA9',
			activeTextColor: '#E91E63',
		},
		{
			themeName: 'theme7',
			buttonColor: '#273135',
			backgroundColor: '#273135',
			textColor: '#ffffff',
			activeColor: '#161B1D',
			header: '#161B1D',
			activeTextColor: '#F2C291',
		},
		{
			themeName: 'theme8',
			buttonColor: '#273135',
			backgroundColor: '#ffffff',
			textColor: '#000000',
			activeColor: '#2DE1C2',
			activeTextColor: '#2DE1C2',
		},
	],
};

const layoutTheme = {
	id: 'layoutTheme',
	label: 'themeChanger.background',
	defaultTheme: themeConfig.layout,
	options: [
		{
			themeName: 'themedefault',
			buttonColor: '#fafafa',
			backgroundColor: '#fafafa',
			cardBackground: '#fff',
			headingColor: '#6a727a',
			textColor: '#000',
		},
		{
			themeName: 'theme1',
			buttonColor: '#ffffff',
			backgroundColor: '#ffffff',
			cardBackground: '#fff',
			headingColor: '#6a727a',
			textColor: '#000',
		},
		{
			themeName: 'theme2',
			buttonColor: '#F9F9F9',
			backgroundColor: '#F9F9F9',
			cardBackground: '#fff',
			headingColor: '#6a727a',
			textColor: '#000',
		},
		{
			themeName: 'theme3',
			buttonColor: '#ebebeb',
			backgroundColor: '#ebebeb',
			cardBackground: '#fff',
			headingColor: '#6a727a',
			textColor: '#000',
		},
		{
			themeName: 'theme4',
			buttonColor: '#D9D9D9',
			backgroundColor: '#D9D9D9',
			cardBackground: '#fff',
			headingColor: '#6a727a',
			textColor: '#000',
		},
		{
			themeName: 'theme5',
			buttonColor: '#D1D1D1',
			backgroundColor: '#D1D1D1',
			cardBackground: '#fff',
			headingColor: '#000',
			textColor: '#000',
		},
		{
			themeName: 'theme6',
			buttonColor: '#2d3038',
			backgroundColor: '#2d3038',
			cardBackground: 'rgba(68, 70, 79, 0.5)',
			headingColor: '#fff',
			textColor: '#949ba2',
		},
	],
};

const footerTheme = {
	id: 'footerTheme',
	label: 'themeChanger.footer',
	defaultTheme: themeConfig.footer,
	options: [
		{
			themeName: 'themedefault',
			buttonColor: '#ffffff',
			textColor: '#323332',
			backgroundColor: '#ffffff',
		},
		{
			themeName: 'theme1',
			buttonColor: '#273135',
			backgroundColor: '#273135',
			textColor: '#ffffff',
		},
		{
			themeName: 'theme2',
			buttonColor: '#241d3b',
			backgroundColor: '#241d3b',
			textColor: '#ffffff',
		},
		{
			themeName: 'theme3',
			buttonColor: '#61696C',
			backgroundColor: '#61696C',
			textColor: '#ffffff',
		},
		{
			themeName: 'theme4',
			buttonColor: '#EBECEC',
			backgroundColor: '#EBECEC',
			textColor: '#000000',
		},
		{
			themeName: 'theme5',
			buttonColor: 'rgba(68,70,79,0.5)',
			backgroundColor: 'rgba(68,70,79,0.5)',
			textColor: '#ffffff',
		},
		{
			themeName: 'theme6',
			buttonColor: '#794c8a',
			backgroundColor: '#794c8a',
			textColor: '#ffffff',
		},
		{
			themeName: 'theme7',
			buttonColor: '#422e62',
			backgroundColor: '#422e62',
			textColor: '#ffffff',
		},
	],
};

const customizedThemes = {
	changeThemes,
	topbarTheme,
	sidebarTheme,
	layoutTheme,
	footerTheme,
};

export function getCurrentTheme(attribute, selectedThemename) {
	let selecetedTheme = {};
	customizedThemes[attribute].options.forEach((theme) => {
		if (theme.themeName === selectedThemename) {
			selecetedTheme = theme;
		}
	});
	return selecetedTheme;
}
export default customizedThemes;
