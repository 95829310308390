import React, { useEffect, useState, useRef } from 'react';
import UserWrapper from './style';
import PageTitle from 'components/common/PageTitle';
import { connect } from 'react-redux';
import Toaster from '../../components/common/Toaster';
import AdminForm from '../../components/admin/AdminForm';
import Api from 'helper/Api';
import { STATUS_CODES } from '../../helper/constant';

const AdminEdit = (props) => {
	const hasParam = props.match.params.id;

	const [editedData, setEditedData] = useState(null);
	const [loading, setloading] = useState(false);
	const [disabled, setDisabled] = useState(false);
	const [loader, setLoader] = useState(true);

	const toaster = useRef();

	useEffect(() => {
		// Api("GET", `sub-admin/view?id=${hasParam}`)
		if (hasParam) {
			Api('GET', `cms/admin/retrieve/data/${hasParam}`)
				.then((res) => {
					setEditedData(res.data.data);
					setLoader(false);
				})
				.catch((err) => {
					setLoader(false);
				});
		}
		setLoader(false);
	}, []);

	const submitFormHandler = (data, action) => {
		if (action === 'add') {
			let params = {
				firstName: data.firstName,
				lastName: data.lastName,
				email: data.email,
				role: data.role,
				//     image: data.profilePhoto,
			};
			const formData = new FormData();
			for (let key in params) {
				formData.append(key, params[key]);
			}
			setloading(true);
			setDisabled(true);
			Api('POST', 'cms/admin/add', formData)
				.then((res) => {
					if (res.status === STATUS_CODES.SUCCESS) {
						if (toaster.current) toaster.current.success(res.data.message);
						props.history.push('/admin');
						setloading(false);
						setDisabled(false);
					} else {
						if (toaster.current) toaster.current.error(res.data.message);
						setloading(false);
						setDisabled(false);
					}
				})
				.catch((err) => {
					if (toaster.current) toaster.current.error(err.response);
					setloading(false);
					setDisabled(false);
				});
		} else if (action === 'edit') {
			let params = {
				id: data.id,
				firstName: data.firstName,
				lastName: data.lastName,
				role: data.role,
				//   image: data.profilePhoto,
			};
			const formData = new FormData();
			for (let key in params) {
				formData.append(key, params[key]);
			}
			setloading(true);
			setDisabled(true);
			Api('PUT', `cms/admin/edit/sub-admin/${hasParam}`, formData)
				.then((res) => {
					if (res.status === STATUS_CODES.SUCCESS) {
						if (toaster.current) toaster.current.success(res.data.message);
						props.history.push('/admin');
						setloading(false);
						setDisabled(false);
					} else {
						if (toaster.current) toaster.current.error(res.data.message);
						setloading(false);
						setDisabled(false);
					}
				})
				.catch((err) => {
					if (toaster.current) toaster.current.error(err.response);
					setloading(false);
					setDisabled(false);
				});
		}
	};

	return (
		<UserWrapper {...props}>
			<div className="pos-relative">
				<PageTitle
					title="sidebar.admin"
					className="plr-15"
					breadCrumb={[
						{
							name: 'sidebar.admin',
						},
						{
							name: hasParam ? 'action.edit' : 'action.add',
						},
					]}
				/>
				<div className="back-icon fs-15 demi-bold-text cursor-pointer" onClick={() => props.history.push('/admin')}>
					<i className="fas fa-step-backward"></i> Back
				</div>
			</div>
			<div className="plr-15">
				<div className="roe-card-style mtb-15">
					<div className="roe-card-header module-header">
						<div className="flex-1 fs-16 demi-bold-text">
							<span className="hash"># </span> {hasParam ? 'Edit' : 'Add'} Admin
						</div>
					</div>
					<div className="roe-card-body">
						<AdminForm
							onSubmit={submitFormHandler}
							editedData={editedData}
							action={hasParam ? 'edit' : 'add'}
							loading={loading}
							disabled={disabled}
							loader={loader}
						/>
					</div>
				</div>
				<Toaster ref={toaster} />
			</div>
		</UserWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.themeChanger,
	};
};

export default connect(mapStateToProps, null)(AdminEdit);
