import { withFormik } from 'formik';
import * as Yup from 'yup';

const formikEnhancer = withFormik({
	validationSchema: Yup.object().shape({
		filterName: Yup.string()
			.trim()
			.required('This field is required'),
		colorCode: Yup.string().required('This field is required'),
		fileName: Yup.string().required('This field is required'),
	}),
	mapPropsToValues: (props) => ({
		id: props && props.editedData && props.editedData.id ? props.editedData.id : '',
		filterName: props && props.editedData && props.editedData.filterName ? props.editedData.filterName : '',
		colorCode: props && props.editedData && props.editedData.colorCode ? props.editedData.colorCode : '',
		fileName: props && props.editedData && props.editedData.fileName ? props.editedData.fileName : '',
		lutSize: props && props.editedData && props.editedData.lutSize ? props.editedData.lutSize : '',
	}),
	handleSubmit: (values) => {},
	displayName: 'CustomValidationForm',
	enableReinitialize: true,
});

export default formikEnhancer;
