import axios from 'axios';
import { baseUrl, STATUS_CODES } from './constant';
import { store } from 'redux/store';
import AuthActions from 'redux/auth/actions';
const { logout } = AuthActions;

const Api = (method, urlData, params) => {
	return new Promise((resolve, reject) => {
		const url = `${baseUrl}${urlData}`;
		const headers = {};

		if (store.getState().auth.accessToken) {
			headers['Authorization'] = 'Bearer ' + store.getState().auth.accessToken;
		}
		let axiosObj = {
			method: method,
			url: url,
			data: params,
			headers: headers,
		};

		// if (params.apiCancelToken) axiosObj['cancelToken'] = params.apiCancelToken;

		axios(axiosObj)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				if (error && error.response && error.response.status === STATUS_CODES.UNAUTHORIZED) {
					store.dispatch(logout());
				}

				if (error && error.response) reject(error);
				else console.log(error);
			});
	});
};
export default Api;
