import Editprofile from 'views/Editprofile/Editprofile';
import ChangePassword from 'views/ChangePassword/ChangePassword';
import StaticPage from 'views/StaticPageManagement/StaticPage';
import StaticPageEdit from 'views/StaticPageManagement/StaticPageEdit';
import Dashboard from '../views/Dashboard/Dashboard';
import Admin from '../views/AdminManagement/Admin';
import AdminEdit from '../views/AdminManagement/AdminEdit';
import User from '../views/UserManagement/User';
import UserEdit from '../views/UserManagement/UserEdit';
import Filter from '../views/FilterManagement/Filter';
import FilterEdit from '../views/FilterManagement/FilterEdit';
import Event from '../views/EventManagement/Event';
import Notification from '../views/NotificationManagement/Notification';
import NotificationEdit from '../views/NotificationManagement/NotificationEdit';
import ShareContent from '../views/ShareContentManagement/ShareContent';
import ShareContentEdit from '../views/ShareContentManagement/ShareContentEdit';
import StaticContent from '../views/StaticContentManagement/StaticContent';

export const dashboardRoutesForAdmin = [
	// Basic routes
	{ path: '/dashboard', component: Dashboard },
	{ path: '/edit-profile', component: Editprofile },
	{ path: '/change-password', component: ChangePassword },
	// Static page routes
	{ path: '/static-page', component: StaticPage },
	{ path: '/static-page/add', component: StaticPageEdit },
	{ path: '/static-page/edit/:id', component: StaticPageEdit },
	// Admin management routes
	{ path: '/admin', component: Admin },
	{ path: '/admin/add', component: AdminEdit },
	{ path: '/admin/edit/:id', component: AdminEdit },
	// User management routes
	{ path: '/user', component: User },
	{ path: '/user/add', component: UserEdit },
	{ path: '/user/edit/:id', component: UserEdit },
	// Filter management routes
	{ path: '/filter', component: Filter },
	{ path: '/filter/add', component: FilterEdit },
	{ path: '/filter/edit/:id', component: FilterEdit },
	// Event management routes
	{ path: '/event', component: Event },
	// Notification management routes
	{ path: '/notification', component: Notification },
	{ path: '/notification/add', component: NotificationEdit },
	// Share Content management routes
	{ path: '/share', component: ShareContent },
	{ path: '/share/add', component: ShareContentEdit },
	{ path: '/share/edit/:id', component: ShareContentEdit },
	// Static Tutorial Video and Static Tap to Start Event Routes
	{ path: '/static-content', component: StaticContent },
];

export const dashboardRoutesForSubAdmin = [
	// Basic routes
	{ path: '/dashboard', component: Dashboard },
	{ path: '/edit-profile', component: Editprofile },
	{ path: '/change-password', component: ChangePassword },
	// Static page routes
	{ path: '/static-page', component: StaticPage },
	// { path: '/static-page/edit/:id', component: StaticPageEdit },
	// Admin management routes
	{ path: '/admin', component: Admin },
	// { path: '/admin/add', component: AdminEdit },
	// { path: '/admin/edit/:id', component: AdminEdit },
	// User management routes
	{ path: '/user', component: User },
	// { path: '/user/add', component: UserEdit },
	// { path: '/user/edit/:id', component: UserEdit },
	// Filter management routes
	{ path: '/filter', component: Filter },
	// { path: '/filter/add', component: FilterEdit },
	// { path: '/filter/edit/:id', component: FilterEdit },
	// Event management routes
	{ path: '/event', component: Event },
	// Notification management routes
	// { path: '/notification', component: Notification },
	// { path: '/notification/add', component: NotificationEdit },
	// Share Content management route
	// { path: '/share', component: ShareContent },
	// { path: '/share/add', component: ShareContentEdit },
	// { path: '/share/edit/:id', component: ShareContentEdit },
];

// export default dashboardRoutes;
