import React from "react";
import UserWrapper from "./user.style";
import PageTitle from "components/common/PageTitle";
import { connect } from "react-redux";
import EditProfileForm from "../../components/editprofile/EditProfileForm";

const UserEdit = props => {
  const submitFormHandler = data => {};

  return (
    <UserWrapper {...props}>
      <div className="pos-relative">
        <PageTitle title="header.editprofile" className="plr-15" />
        <div
          className="back-icon fs-15 demi-bold-text cursor-pointer"
          onClick={() => props.history.push("/dashboard")}
        >
          <i className="fas fa-step-backward"></i> Back
        </div>
      </div>
      <div className="plr-15">
        <div className="roe-card-style mtb-15">
          <div className="roe-card-header module-header"></div>
          <div className="roe-card-body">
            <EditProfileForm onSubmit={submitFormHandler} />
          </div>
        </div>
      </div>
    </UserWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(UserEdit);
