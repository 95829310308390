import React from "react";
import ReactHtmlParser from "html-react-parser";
const viewModal = (props) => {
  let data = props.staticPageData;
  return (
    <div className="wp-100">
      <div
        className="fs-15 demi-bold-text cursor-pointer float-right"
        onClick={() => props.toggle()}
      >
        <i className="fa fa-times"></i>
      </div>
      <div className="text-center fs-20 font-weight-bolder">
        Static page information
      </div>

      <div className="row">
        <div className="col-12 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Page Name
            </div>
            <div className="text-left">{data.title}</div>
          </div>
        </div>
        <div className="col-12 pt-4">
          <div>
            <div style={{ fontWeight: "bolder", textAlign: "left" }}>
              Content
            </div>
            <div
              className="text-left"
              //dangerouslySetInnerHTML={{ __html: data.content }}
            />
            {ReactHtmlParser(data.content)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default viewModal;
