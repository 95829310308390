import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    title: Yup.string().required("This field is required"),
    content: Yup.string().required("This field is required"),
  }),
  mapPropsToValues: props => ({
    id : props.editedData
      ? props && props.editedData && props.editedData.id
      : "",
    title : props.editedData
      ? props && props.editedData && props.editedData.title
      : "",
    content: props.editedData
      ? props && props.editedData && props.editedData.content
      : "",
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
