import React from 'react';
import PageWrapper from './user.style';
import PageTitle from 'components/common/PageTitle';
import { connect } from 'react-redux';
import StaticContentTable from '../../components/static_content/StaticContentTable';

const StaticContent = (props) => {
	return (
		<PageWrapper {...props}>
			<PageTitle title="sidebar.static-content" className="plr-15" />
			<div className="user-tbl">
				<StaticContentTable {...props} />
			</div>
		</PageWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.themeChanger,
	};
};

export default connect(mapStateToProps, null)(StaticContent);
