import React from 'react';
import { compose } from 'redux';
import enhancer from './validator';
import './StaticPageForm.css';
import SmallLoader from '../common/SmallLoader';
import Loader from 'components/common/Loader';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const StaticPageForm = (props) => {
	const { handleChange, handleBlur, errors, setFieldValue, touched, submitCount, onSubmit, values, action } = props;

	const Error = (props) => {
		const field1 = props.field;
		if ((errors[field1] && touched[field1]) || submitCount > 0) {
			return <span className={props.class ? props.class : 'error-msg'}>{errors[field1]}</span>;
		} else {
			return <span />;
		}
	};

	const handleSubmit = () => {
		let { values, isValid, handleSubmit } = props;

		if (isValid) {
			onSubmit(values, action);
		}
		handleSubmit();
	};

	return (
		<div>
			{!props.loader ? (
				<form>
					<div className="form-group">
						<label className="fs-16 medium-text">Title</label>
						<input
							type="text"
							className="form-control react-form-input"
							id="title"
							onChange={handleChange}
							onBlur={handleBlur}
							value={values.title}
							placeholder="Title"
							disabled
						/>
						<Error field="title" />
					</div>
					<div className="form-group">
						<label className="fs-16 medium-text">Content</label>
						{values.title === 'Disclaimer' ? (
							<textarea
								className="form-control react-form-input"
								id="content"
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.content}
								placeholder="Share Content"
								rows="10"
							/>
						) : (
							<ReactQuill
								defaultValue={''}
								value={values.content}
								onChange={(content) => {
									setFieldValue('content', content);
								}}
							/>
						)}
						<Error field="content" />
					</div>
					<div>
						<div>
							{!props.loading && (
								<button
									type="button"
									className="c-btn c-info form-button fs-16 demi-bold-text"
									style={{ maxWidth: '125px' }}
									onClick={() => handleSubmit()}
									disabled={props.disabled}
								>
									Submit
								</button>
							)}
							{props.loading && <SmallLoader />}
						</div>
					</div>
				</form>
			) : (
				<Loader loading={props.loader} />
			)}
		</div>
	);
};

export default compose(enhancer)(StaticPageForm);
