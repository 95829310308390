import Layout from "../layouts/DashboardLayout";
import { Login, Register, ForgotPassword } from "views/pages/index";
import Otp from "../views/pages/authentication/Otp";
import SetPassword from "../views/pages/authentication/SetPassword";
const indexRoutes = [
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/otp", component: Otp },
  { path: "/forgot-password", component: ForgotPassword },
  { path: "/set-Password/:iv/:data", component: SetPassword },
  { path: "/", component: Layout },
];

export default indexRoutes;
