import React from 'react';
import PageWrapper from './style';
import PageTitle from 'components/common/PageTitle';
import { connect } from 'react-redux';
import EventTable from '../../components/event/EventTable';

const Event = (props) => {
	return (
		<PageWrapper {...props}>
			<PageTitle title="sidebar.event" className="plr-15" />
			<div className="user-tbl">
				<EventTable {...props} />
			</div>
		</PageWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.themeChanger,
	};
};

export default connect(mapStateToProps, null)(Event);
