import React, { useState } from 'react';
import HeaderWrapper from './header.style';
import IntlMessages from 'util/intlMessages';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { connect } from 'react-redux';
import { compose } from 'redux';
import AuthActions from 'redux/auth/actions';
import { withRouter } from 'react-router-dom';
import { AppName } from 'helper/constant';
import Avatar from 'react-avatar';

const { logout } = AuthActions;
const Header = (props) => {
	const [profilePopover, setProfilePopover] = useState(false);
	const { drawerMiniMethod, mini } = props;
	const userSignout = () => {
		props.logout();
	};

	const profilePopovertoggle = () => {
		setProfilePopover(!profilePopover);
	};

	return (
		<HeaderWrapper {...props}>
			<div className="headerBack">
				<ul className="list-inline ma-0">
					<li className="list-inline-item feed-text-area-icon">
						<div className="drawer-handle-arrow">
							{mini ? (
								<button
									className="top-header-icon c-btn"
									style={{ backgroundColor: '#ff6767' }}
									onClick={drawerMiniMethod()}
								>
									<i className="fas fa-arrow-right" />
								</button>
							) : (
								<button
									className="top-header-icon c-btn"
									style={{ backgroundColor: '#ff6767' }}
									onClick={drawerMiniMethod()}
								>
									<i className="fas fa-arrow-left" />
								</button>
							)}
						</div>
						<div className="mini-drawer-menu-icon" onClick={drawerMiniMethod()}>
							<i className="fas fa-bars" /> <span className="app-name">{AppName}</span>
						</div>
					</li>
					<li className="list-inline-item feed-text-area-icon pull-right">
						<div id="profilePopover" onClick={profilePopovertoggle} className="top-header-profile-class">
							{props && props.profilePic ? (
								<img
									src={props.profilePic}
									alt="loading"
									style={{ height: '40px', width: '40px', borderRadius: '50%' }}
								/>
							) : (
								<Avatar name={`${props.firstName} ${props.lastName}`} size={36} round={true} />
							)}
						</div>
					</li>
				</ul>
				<Popover
					className="language-popover-width"
					placement="bottom"
					isOpen={profilePopover}
					target="profilePopover"
					toggle={profilePopovertoggle}
					trigger="hover"
				>
					<PopoverHeader className="custom-popover-header ">
						<ul className="list-inline ma-0">
							<li className="list-inline-item notification-popover-profile">
								{props && props.profilePic ? (
									<img
										src={props.profilePic}
										alt="loading"
										style={{
											height: '40px',
											width: '40px',
											borderRadius: '50%',
										}}
									/>
								) : (
									<Avatar name={`${props.firstName} ${props.lastName}`} size={36} round={true} />
								)}
							</li>
							<li className="list-inline-item notification-popover-profile">
								{`${props.firstName} ${props.lastName}`}
							</li>
						</ul>
					</PopoverHeader>
					<PopoverBody className="pa-0">
						<div className="container-fluid grid-popover pa-0">
							<ul
								className="list-inline plr-10 ma-0 language-list-hover"
								onClick={() => {
									props.history.push(`/edit-profile`);
								}}
							>
								<li className="list-inline-item pa-5">
									<i className="fas fa-user-alt" />
								</li>
								<li className="list-inline-item pa-5">
									<IntlMessages id="header.editprofile" />
								</li>
							</ul>

							<ul
								className="list-inline plr-10 language-list-hover ma-0"
								onClick={() => {
									props.history.push(`/change-Password`);
								}}
							>
								<li className="list-inline-item pa-5">
									<i className="fas fa-cog" />
								</li>
								<li className="list-inline-item pa-5">
									<div>Change Password</div>
								</li>
							</ul>

							<ul className="list-inline plr-10 language-list-hover ma-0" onClick={userSignout}>
								<li className="list-inline-item pa-5">
									<i className="fas fa-sign-out-alt" />
								</li>
								<li className="list-inline-item pa-5">
									<IntlMessages id="header.signout" />
								</li>
							</ul>
						</div>
					</PopoverBody>
				</Popover>{' '}
				{/* Profile popover */}
			</div>
		</HeaderWrapper>
	);
};
const mapStateToProps = (state) => {
	return {
		firstName: state.auth.firstName,
		lastName: state.auth.lastName,
		profilePic: state.auth.profilePic,
		id: state.auth.id,
	};
};
export default compose(withRouter, connect(mapStateToProps, { logout }))(Header);
