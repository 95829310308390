import React, { useState, useRef } from 'react';
import { ForgotIcon } from 'helper/constant';
import Toaster from '../../../components/common/Toaster';
import { withRouter } from 'react-router-dom';
import Otp from './Otp';
import Button from '../../../components/button/Button';
import Api from '../../../helper/Api';
import { STATUS_CODES } from 'helper/constant';
const ForgotPassword = (props) => {
	const loginContainer = {
		backgroundPosition: 'center center',
		backgroundSize: 'cover',
		position: 'fixed',
		overflow: 'auto',
		top: 0,
		bottom: 0,
		backgroundColor: '#FEF5F5',
	};

	const toaster = useRef();
	const [email, setEmail] = useState('');
	//const [token, setToken] = useState({});

	const [view, setView] = useState(true);
	const [loading, setloading] = useState(false);

	function submitHandler(e) {
		e.preventDefault();
		const params = {
			email: email,
		};
		setloading(true);
		Api('POST', 'cms/admin/generateotp', params)
			.then((res) => {
				if (res.status === STATUS_CODES.SUCCESS) {
					//      setToken(res.data.data.otp_token)
					if (toaster.current) toaster.current.success(res.data.message);
					setTimeout(() => {
						setView(false);
						setloading(false);
					}, 1000);
				}
			})
			.catch((err) => {
				if (toaster.current) toaster.current.error(err.response);
				setloading(false);
			});
	}

	return view ? (
		<div className="container-fluid" style={loginContainer}>
			<div className="form-container">
				<div className="login-icon">
					<img src={ForgotIcon} alt="icon" height="100px" />
				</div>
				<div className="login-title">Forgot Password ?</div>
				<div className="text-center form-info-text plr-24 mt-16">
					Provide your e-mail address to reset your password
				</div>
				<form className="pa-24" onSubmit={(e) => submitHandler(e)}>
					<div className="form-group">
						<input
							type="email"
							required
							onChange={(e) => {
								setEmail(e.target.value);
							}}
							className="form-control react-form-input"
							id="exampleInputEmail1"
							aria-describedby="emailHelp"
							placeholder="Enter email"
						/>
					</div>
					<Button
						type="submit"
						loading={loading}
						className="btn form-button"
						dataStyle="expand-right"
						style={{ position: 'relative', backgroundColor: '#f58322' }}
					>
						Get OTP
					</Button>
					<button
						type="button"
						className="btn form-button "
						style={{ marginTop: '10px', backgroundColor: '#FFAD5D' }}
						onClick={() => props.history.push('./login')}
					>
						Back
					</button>
				</form>
			</div>
			<Toaster ref={toaster} />
		</div>
	) : (
		// <Otp email={email} otp_token={token}/>
		<Otp email={email} />
	);
};

export default withRouter(ForgotPassword);
