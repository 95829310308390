import React from 'react';
import StyleWrapper from './style';
import PageTitle from 'components/common/PageTitle';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LineChart from './LineChart';
import Loader from '../../components/common/Loader';
import { useState, useEffect, useRef } from 'react';
import Api from 'helper/Api';
import Avatar from 'react-avatar';
import moment from 'moment';

const arrColor = [
	'linear-gradient(45deg,#94db18,white)',
	'linear-gradient(45deg,#1ebae6,white)',
	'linear-gradient(45deg,#c287f3,white)',
	'linear-gradient(45deg,#94db18,white)',
	'linear-gradient(45deg,#e66043,white)',
	'linear-gradient(45deg,#435be6,white)',
	'linear-gradient(45deg,#eb83e2,white)',
	'linear-gradient(45deg,#94f58b,white)',
	'linear-gradient(45deg,pink,white)',
	'linear-gradient(45deg,#eec81e,white)',
];

const Dashboard = (props) => {
	const toaster = useRef();

	const [loader, setLoader] = useState(true);
	const [dashboardData, setDashboardData] = useState({});
	const [userData, setUserData] = useState({
		labels: [],
		datasets: [],
	});

	const [eventData, setEventData] = useState({
		labels: [],
		datasets: [],
	});

	useEffect(() => {
		setLoader(true);
		Api('POST', 'cms/dashboard')
			.then((res) => {
				if (res.status === 200) {
					setDashboardData(res.data.data);
					setUserData({
						labels: res.data.data.userDataForAnalytics.map((data) => data.MONTH),
						datasets: [
							{
								fill: true,
								borderColor: 'rgb(250, 47, 11)',
								backgroundColor: 'rgba(255, 138, 123, 0.5)',
								data: res.data.data.userDataForAnalytics.map((data) => data.COUNT),
							},
						],
					});
					setEventData({
						labels: res.data.data.eventDataForAnalytics.map((data) => data.MONTH),
						datasets: [
							{
								fill: true,
								borderColor: 'rgb(255, 163, 77)',
								backgroundColor: 'rgba(255, 198, 123, 0.452)',
								data: res.data.data.eventDataForAnalytics.map((data) => data.COUNT),
							},
						],
					});
					setLoader(false);
				} else {
					setLoader(false);
					if (toaster.current) toaster.current.error(res.data.message);
				}
			})
			.catch((err) => {
				setLoader(false);
				if (toaster.current) toaster.current.error(err.response);
			});
	}, []);

	return (
		<StyleWrapper {...props}>
			<PageTitle title="sidebar.dashboard" className="plr-15" />
			{!loader ? (
				<div className="div-container">
					<div className="parent-dashboard-count">
						<div className="dashboard-count">
							<p className="font-count">Users</p>
							<p className="font-count-content">{dashboardData && dashboardData.users && dashboardData.users.count}</p>
						</div>
						<div className="dashboard-count">
							<p className="font-count">Events</p>
							<p className="font-count-content">{dashboardData && dashboardData.eventCount}</p>
						</div>
						<div className="dashboard-count">
							<p className="font-count">Media</p>
							<p className="font-count-content">{dashboardData && dashboardData.mediaCount}</p>
						</div>
						<div className="dashboard-count">
							<p className="font-count">Filters</p>
							<p className="font-count-content">{dashboardData && dashboardData.filterCount}</p>
						</div>
					</div>

					<div className="parent-dashboard-chart">
						<div className="dashboard-chart">
							<p className="font-count">Users</p>
							<div className="chart">
								<LineChart chartData={userData} />
							</div>
						</div>
						<div className="dashboard-chart event">
							<p className="font-count">Events</p>
							<div className="chart">
								<LineChart chartData={eventData} />
							</div>
						</div>
					</div>

					<div className="parent-dashboard-user-list">
						<div className="dashboard-user-list">
							<p className="font-count user-list-heading">User List</p>

							{dashboardData &&
								dashboardData.users &&
								dashboardData.users.rows.map((userObj, index) => {
									return (
										<div className="user-list">
											<div className="user-list-container">
												<Avatar
													className="avatar"
													name={`${userObj.firstName} ${userObj.lastName}`}
													size={32}
													round={true}
													color={arrColor[index]}
													fgColor={'black'}
												/>

												<div className="user-list-name">
													<div>
														{userObj.firstName} {userObj.lastName}
													</div>
													<div className="user-list-email">{userObj.email}</div>
												</div>
											</div>

											<div className="user-list-date">
												<div>{moment(userObj.createdAt).format('MMM-DD-YYYY')}</div>
												<div className="user-list-time">{moment(userObj.createdAt, 'hh:mm').format('LT')}</div>
											</div>
										</div>
									);
								})}
						</div>
					</div>
				</div>
			) : (
				<div className="dashboard-loader">
					<Loader loading={loader} />
				</div>
			)}
		</StyleWrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		...state.themeChanger,
	};
};

export default connect(mapStateToProps, withRouter, null)(Dashboard);
