import { withFormik } from 'formik';
import * as Yup from 'yup';

const formikEnhancer = withFormik({
	validationSchema: Yup.object().shape({
		title: Yup.string()
			.trim()
			.required('This field is required'),
		content: Yup.string()
			.trim()
			.required('This field is required'),
	}),
	mapPropsToValues: (props) => ({
		id: props && props.editedData && props.editedData.id ? props.editedData.id : '',
		title: props && props.editedData && props.editedData.filterName ? props.editedData.title : '',
		content: props && props.editedData && props.editedData.colorCode ? props.editedData.content : '',
	}),
	handleSubmit: (values) => {},
	displayName: 'CustomValidationForm',
	enableReinitialize: true,
});

export default formikEnhancer;
